import * as i from 'types';
import * as React from 'react';
import { useTranslation } from 'next-i18next';

import { useCurrentLocation, useIntersection } from 'services/hooks';
import BurgerIcon from 'vectors/internal/burger.svg';
import { StyledLink } from 'common/interaction';
import { LocationPin } from 'common/layout';

import { UserMenu } from '../UserMenu';
import {
  HeaderMenuContainer,
  HeaderMenuLeftContainer,
  HeaderMenuLogoIcon,
  HeaderMenuLinkWrapper,
  HeaderMenuLogoWrapper,
  HeaderMenuSecondaryLinkWrapper,
  HeaderMenuBurgerWrapper,
  HeaderMenuAnimationContainer,
  HeaderMenuOverflowWrapper,
  HeaderMenuLink,
} from './styled';

export const MenuDesktop: React.FC<MenuDesktopProps> = ({
  menuItems,
  logoVisible = true,
  hasFixedVideoHeader = false,
  callToActionButton,
  shouldAnimate = false,
  toggleMenu,
  toggleBookingSidebar,
  toggleLocationSidebar,
}) => {
  const { t } = useTranslation();
  const [isSticky, setIsSticky] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const isIntersecting = useIntersection(ref);

  const nearestCity = useCurrentLocation()?.currentLocation?.name;
  const [currentLocation, setCurrentLocation] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    setCurrentLocation(nearestCity);
  }, [nearestCity]);

  const animations = hasFixedVideoHeader
    ? {
        initial: { marginTop: '100vh' },
        animate: { marginTop: '93.5vh' },
        transition: {
          delay: shouldAnimate ? 1.7 : 0,
          duration: shouldAnimate ? 0.5 : 0,
          ease: 'easeInOut',
        },
      }
    : {
        initial: { marginTop: 0 },
        animate: { marginTop: 0 },
        transition: { delay: 0, duration: 0 },
      };

  React.useEffect(() => {
    if (isIntersecting) setIsSticky(true);
  }, [isIntersecting]);

  return (
    <HeaderMenuContainer
      aria-label={t('Main') as string}
      $hasFixedVideoHeader={hasFixedVideoHeader}
      $isSticky={isSticky}
      ref={ref}
      initial={animations.initial}
      animate={animations.animate}
      transition={animations.transition}
    >
      <HeaderMenuLeftContainer>
        <HeaderMenuBurgerWrapper onClick={toggleMenu}>
          <BurgerIcon />
        </HeaderMenuBurgerWrapper>
        <HeaderMenuOverflowWrapper>
          <HeaderMenuAnimationContainer logoVisible={logoVisible}>
            <HeaderMenuLogoWrapper>
              <StyledLink href="/">
                <HeaderMenuLogoIcon />
              </StyledLink>
            </HeaderMenuLogoWrapper>
            <HeaderMenuLinkWrapper>
              {menuItems.map((menuItem) => (
                <StyledLink
                  key={menuItem.sys.id}
                  href={menuItem.fields.url}
                  {...(menuItem.fields.openInNewTab && {
                    target: '_blank',
                  })}
                >
                  {menuItem.fields.title}
                </StyledLink>
              ))}
            </HeaderMenuLinkWrapper>
          </HeaderMenuAnimationContainer>
        </HeaderMenuOverflowWrapper>
      </HeaderMenuLeftContainer>
      <HeaderMenuSecondaryLinkWrapper>
        {callToActionButton ? (
          callToActionButton
        ) : (
          <HeaderMenuLink onClick={toggleBookingSidebar} $isBold>
            {t('Book a ride')}
          </HeaderMenuLink>
        )}
        {currentLocation && (
          <HeaderMenuLink onClick={toggleLocationSidebar} $withIcon>
            <LocationPin /> {currentLocation}
          </HeaderMenuLink>
        )}
        <UserMenu />
      </HeaderMenuSecondaryLinkWrapper>
    </HeaderMenuContainer>
  );
};

type MenuDesktopProps = {
  menuItems: i.TypeNavigationItem[];
  logoVisible?: boolean;
  hasFixedVideoHeader?: boolean;
  callToActionButton?: React.ReactElement;
  toggleMenu: () => void;
  toggleBookingSidebar: () => void;
  toggleLocationSidebar: () => void;
  shouldAnimate?: boolean;
};
