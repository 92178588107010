import React from 'react';

import { ExtendedMotionDiv } from './styled';

const variants = {
  enter: {
    x: 1000,
    opacity: 0,
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: {
    zIndex: 0,
    x: -1000,
    opacity: 0,
  },
};

const transition = {
  x: { type: 'spring', stiffness: 200, damping: 30 },
  opacity: { duration: 0.5 },
};

export const SlideTransition: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <ExtendedMotionDiv
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={transition}
    >
      {children}
    </ExtendedMotionDiv>
  );
};
