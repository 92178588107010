import styled from 'styled-components';
import { media } from 'styles/utils';

export const FooterMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px 27px 20px;

  ${media.desktop`
    padding: 50px;
    flex-direction: row;
  `}
`;

export const SecondaryLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.desktop`
    flex-shrink: 0;
  `}
`;
