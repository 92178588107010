import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { Text } from 'common/typography';

export const SubFilter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.medium};
`;

export const SubFilterHeading = styled.button`
  position: relative;
  appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  > svg,
  > img {
    width: 18px;
    height: 18px;
    margin-right: 14px;
  }
`;

export const Arrow = styled.span<ArrowProps>`
  margin-left: auto;
  transform: rotate(90deg);
  transition: transform 500ms;
  font-family: ${({ theme }) => theme.fonts.favoritPro};

  ${({ $rotate }) =>
    $rotate &&
    css`
      transform: rotate(-90deg);
    `};
`;

type ArrowProps = {
  $rotate: boolean;
};

export const FilterTags = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 0 32px;

  > span {
    margin: 0 5px 10px;
    cursor: pointer;
  }
`;

export const Indicator = styled(Text)`
  position: absolute;
  top: -6px;
  margin-left: 4px;
`;
