import styled, { css } from 'styled-components';

import { hexToRgba } from 'services/style';

export const Tag = styled.span<TagProps>`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  line-height: 100%;
  letter-spacing: -0.02em;
  padding: 7px 15px;
  border: 1px solid ${({ theme }) => hexToRgba(theme.colors.text, '20%')};
  color: ${({ theme }) => theme.colors.text};
  border-radius: 120px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  ${({ small }) =>
    small &&
    css`
      padding: 5px 15px;
    `}

  ${({ large }) =>
    large &&
    css`
      padding: 12px 22px;
      border-width: 2px;
    `}

  ${({ $active }) =>
    $active &&
    css`
      border-image: ${({ theme }) =>
        `linear-gradient(90deg, ${theme.colors.gray.medium}, ${theme.colors.blue}) 2`};
    `}

  ${({ highlighted }) =>
    highlighted &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      border: none;
    `}

  > svg, > img {
    display: block;
    margin-right: 8px;
    width: 14px;
    height: 14px;
  }

  ${({ smallSvg }) =>
    smallSvg &&
    css`
      > svg,
      > img {
        width: 6px;
        height: 6px;
      }
    `}
`;

type TagProps = {
  small?: boolean;
  large?: boolean;
  $active?: boolean;
  highlighted?: boolean;
  smallSvg?: boolean;
};
