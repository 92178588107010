import { hexToRgba } from 'services/style';
import styled, { css } from 'styled-components';

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  min-height: 56px;
  padding: 12px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: border 0.3s;

  ${({ isActive }) => isActive && css`
    border: 1px solid ${({ theme }) => theme.colors.blue};
  `}

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.beige};
  }
`;

type ContainerProps = {
  isActive?: boolean;
};

export const DayText = styled.p`
  font-family: ${({ theme }) => theme.fonts.bigShouldersDisplay};
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  text-transform: uppercase;
  font-feature-settings: 'ss04' on;
  color: ${({ theme }) => theme.colors.text};
  margin: 0 0 4px;
`;

export const DateText = styled.p`
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  color: ${({ theme }) => hexToRgba(theme.colors.text, '60%')};
  margin: 0;
`;
