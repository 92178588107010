import * as i from 'types';
import * as React from 'react';

import { useBookingSidebar, useLocationSidebar } from 'services/hooks';
import { BookingSidebar } from 'modules/booking';
import { CurrentLocationSidebar } from 'modules/currentLocation';

import { MenuDesktop, MenuMobile } from './components';

export const HeaderMenu: React.FC<HeaderMenuProps> = ({
  menuItems,
  headerLocations,
  logoVisible = true,
  hasFixedVideoHeader = false,
  shouldAnimate = false,
  callToActionButton,
}) => {
  const [menuVisible, setMenuVisible] = React.useState(false);
  const { toggleBookingSidebar } = useBookingSidebar();
  const { toggleLocationSidebar, locationSidebarVisible } = useLocationSidebar();

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <>
      <MenuMobile
        menuItems={menuItems}
        visible={menuVisible}
        menuVisible={menuVisible}
        toggleMenu={toggleMenu}
        toggleBookingSidebar={toggleBookingSidebar}
        toggleLocationSidebar={toggleLocationSidebar}
        callToActionButton={callToActionButton}
      />
      <MenuDesktop
        menuItems={menuItems}
        logoVisible={logoVisible}
        hasFixedVideoHeader={hasFixedVideoHeader}
        toggleMenu={toggleMenu}
        toggleBookingSidebar={toggleBookingSidebar}
        toggleLocationSidebar={toggleLocationSidebar}
        callToActionButton={callToActionButton}
        shouldAnimate={shouldAnimate}
      />
      <BookingSidebar />
      <CurrentLocationSidebar
        isVisible={locationSidebarVisible}
        toggleVisibility={toggleLocationSidebar}
        locations={headerLocations}
      />
    </>
  );
};

type HeaderMenuProps = {
  menuItems: i.TypeNavigationItem[];
  headerLocations: i.HeaderLocation[];
  logoVisible?: boolean;
  hasFixedVideoHeader?: boolean;
  callToActionButton?: React.ReactElement;
  shouldAnimate?: boolean;
};
