import styled, { css } from 'styled-components';

import { hexToRgba } from 'services/style';
import ArrowRightIcon from 'vectors/internal/arrow-right.svg';
import { media } from 'styles/utils';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0 20px;

  ${media.desktop`
    padding: 0 50px;
  `}

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const ArrowIcon = styled(ArrowRightIcon) <ArrowIconProps>`
  width: 32px;
  height: auto;

  ${({ $isLeft }) => $isLeft && css`
    transform: rotate(180deg);
  `}
`;

type ArrowIconProps = {
  $isLeft?: boolean;
};

const ArrowContainer = css`
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  cursor: pointer;
`;

export const LeftArrowContainer = styled.div`
  ${ArrowContainer}
  left: 0;
  background: linear-gradient(to right, ${({ theme }) => hexToRgba(theme.colors.springWood, '1')},
    ${({ theme }) => hexToRgba(theme.colors.springWood, '0.9')},
    ${({ theme }) => hexToRgba(theme.colors.springWood, '0')});
`;

export const RightArrowContainer = styled.div`
  ${ArrowContainer}
  right: 0;
  background: linear-gradient(to right, ${({ theme }) => hexToRgba(theme.colors.springWood, '0')},
    ${({ theme }) => hexToRgba(theme.colors.springWood, '0.9')},
    ${({ theme }) => hexToRgba(theme.colors.springWood, '1')});
`;
