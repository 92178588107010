import styled from 'styled-components';

import { Headline3 } from 'common/typography';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const HeaderContainer = styled.div`
  padding: 120px 0 60px;
`;

export const Title = styled(Headline3)`
  text-align: center;
`;

export const ContentContainer = styled.div`
  padding: 40px 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
`;
