import React from 'react';
import { useTranslation } from 'next-i18next';

import { isToday, formatLongDayMonth, getWeekDay } from 'services/date';
import { getDays } from 'services/days';

import { Container, DayText, DateText } from './styled';

export const BookingDay: React.FC<BookingDayProps> = ({ date, onSelectDate, isActive }) => {
  const { t } = useTranslation();
  const days = getDays();
  const dayNameIndex = getWeekDay(date);

  return (
    <Container onClick={() => onSelectDate(date)} isActive={isActive}>
      <DayText>{isToday(date) ? t('Today') : t(days[dayNameIndex]).substr(0, 3)}</DayText>
      <DateText>{formatLongDayMonth(date)}</DateText>
    </Container>
  );
};

type BookingDayProps = {
  date: string;
  onSelectDate: (date: string) => void;
  isActive?: boolean;
};
