import styled, { css } from 'styled-components';

import LogoIcon from 'vectors/internal/logo.svg';
import CloseIcon from 'vectors/internal/close.svg';
import ArrowLeftIcon from 'vectors/internal/arrow-left.svg';
import { media } from 'styles/utils';

export const SplitMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  top: 0;
  height: 50px;
  padding: 0 10px;

  ${media.desktop`
    padding: 0 20px;
    height: 60px;
  `}
`;

export const LogoContainer = styled.div<LogoContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  a {
    display: flex;
    justify-content: center;
  }

  ${({ $showBackButton }) => !$showBackButton && css`
    justify-content: flex-start;
    padding-left: 10px;
  `}
`;

type LogoContainerProps = {
  $showBackButton?: boolean;
};

export const Logo = styled(LogoIcon)<LogoProps>`
  width: 82px;
  height: 24px;
  transition: color 0.3s;

  ${({ $isWhite, theme }) => $isWhite && css`
    color: ${theme.colors.white};
  `}
`;

type LogoProps = {
  $isWhite: boolean;
};

export const CloseButton = styled(CloseIcon)<CloseButtonProps>`
  width: 22px;
  height: 22px;
  opacity: 1;
  transition: opacity 0.2s, color 0.3s;

  ${({ $isWhite, theme }) => $isWhite && css`
    color: ${theme.colors.white};
  `}
`;

type CloseButtonProps = {
  $isWhite: boolean;
};

export const ButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  padding: 5px;

  &:hover > svg {
    opacity: 0.4;
  }
`;

export const BackButton = styled(ArrowLeftIcon)<BackButtonProps>`
  width: 16px;
  height: 14px;
  opacity: 1;
  transition: opacity 0.2s, color 0.3s;

  ${({ $isWhite, theme }) => $isWhite && css`
    color: ${theme.colors.white};
  `}
`;

type BackButtonProps = {
  $isWhite: boolean;
};

export const RightContainer = styled.div`
  display: flex;

  & >:not(:last-child) {
    margin-right: 26px;
  }
`;
