import * as i from 'types';
import styled from 'styled-components';

import { hexToRgba } from 'services/style';
import { FocalPointImage } from 'common/layout/FocalPointImage';

export const Container = styled.div<ContainerProps>`
  position: absolute;
  top: ${({ position }) => position.y}px;
  left: ${({ position }) => position.x}px;
  padding-right: 20px;
`;

type ContainerProps = {
  position: i.StudioLayoutPosition;
};

export const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  overflow: hidden;
  background-color: ${({ theme }) => hexToRgba(theme.colors.white, '0.1')};
`;

export const Image = styled(FocalPointImage)`
  width: 60px;
  height: 60px;
  object-fit: cover;
`;
