import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { Thumbnail } from 'common/general';
import { Text, Heading } from 'common/typography';

export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  padding: 0 10px;
`;

export const ExtendedText = styled(Text)`
  opacity: 0.6;
  max-width: 155px;
  text-align: center;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: -0.01em;
  font-feature-settings: 'ordn' on, 'ss04' on, 'ss06' on, 'liga' off;
`;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  margin-top: 115px;
`;

export const ExtendedHeading = styled(Heading)`
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.03em;
  font-feature-settings: 'ss04' on, 'calt' off;
  font-weight: 100;
  margin: 0 0 20px 0;

  ${media.desktop`
    font-size: 30px;
    line-height: 120%;
    margin: 0 0 10px 0;
  `}
`;

export const WhiteBlock = styled.div<WhiteBlockProps>`
  width: 100%;
  max-width: 545px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px 20px 30px 20px;
  margin: 50px 0 30px;
  flex-direction: column;

  ${media.desktop`
    margin: 50px 0 77px;
  `}

  ${media.tablet<WhiteBlockProps>`
    min-height: 700px;

    ${({ small }) =>
      small &&
      css`
        min-height: 0;
      `}
  `}
`;

type WhiteBlockProps = {
  small?: boolean;
};

export const ImageContainer = styled.div`
  margin: 0 0 30px 0;
`;

export const Image = styled(Thumbnail)`
  width: 96px;
  height: 96px;
`;

export const ExtendedWhiteBlockText = styled(Text)<ExtendedWhiteBlockTextProps>`
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.02em;

  ${({ $isBold }) =>
    $isBold &&
    css`
      font-size: 20px;
      line-height: 23px;
      font-weight: 600;
    `}

  img, svg {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
`;

type ExtendedWhiteBlockTextProps = {
  $isBold?: boolean;
};

export const ExtendedBigShoulderText = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.bigShouldersDisplay};
  font-weight: 800;
  font-size: 48px;
  line-height: 105%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-feature-settings: 'calt' off;
  text-align: center;
  max-width: 65%;
  margin-top: 10px;
`;

export const LocationContainer = styled.div`
  margin-top: 47px;

  ${ExtendedWhiteBlockText}:not(:first-child) {
    margin-top: 37px;
  }
`;
