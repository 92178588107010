import React from 'react';
import { AnimatePresence } from 'framer-motion';

import { useRouter } from 'hooks';
import { useBookingSidebar } from 'services/hooks';
import { Sidebar } from 'common/interaction';
import { SplitMenu } from 'common/layout';

import {
  Schedule,
  BookingMap,
  BookingStep,
  BookingWaitlistSuccess,
  BookingSelectSeries,
  BookingSuccess,
} from '../';
import { ContentContainer, Overlay } from './styled';

export const BookingSidebar: React.FC = () => {
  const {
    bookingSidebarVisible,
    backButtonVisible,
    selectedClass,
    step,
    filterOpen,
    selectedSpots,
    setFilterOpen,
    handleSelectClass,
    handleSelectSpot,
    handleGoToSelectSeries,
    handleGoBack,
    toggleBookingSidebar,
    handleBooking,
  } = useBookingSidebar();

  const route = useRouter<Queries>();
  const selectedClassId = route.query.classId || selectedClass?.id;

  return (
    <Sidebar
      filterOpen={filterOpen}
      isVisible={bookingSidebarVisible}
      toggleVisibility={toggleBookingSidebar}
      step={step}
    >
      <ContentContainer $step={step}>
        <Overlay $visible={filterOpen} onClick={() => filterOpen && setFilterOpen(false)} />
        <SplitMenu
          handleGoBack={handleGoBack}
          handleClose={toggleBookingSidebar}
          showBackButton={backButtonVisible}
          isWhite={step === 'map'}
          showLogo
          showLocation={step === 'schedule'}
        />
        <AnimatePresence initial={false} exitBeforeEnter>
          {step === 'schedule' && (
            <BookingStep key="schedule">
              <Schedule onSelectClass={handleSelectClass} />
            </BookingStep>
          )}
          {step === 'map' && selectedClassId && (
            <BookingStep key="map">
              <BookingMap
                classId={selectedClassId}
                selectedSpots={selectedSpots}
                onSelectSpot={handleSelectSpot}
                onSubmit={handleGoToSelectSeries}
              />
            </BookingStep>
          )}
          {step === 'selectSeries' && selectedClassId && (
            <BookingStep key="selectSeries">
              <BookingSelectSeries
                classId={selectedClassId}
                selectedSpots={selectedSpots}
                onSubmitBooking={handleBooking}
              />
            </BookingStep>
          )}
          {step === 'successWaitlist' && (
            <BookingStep key="successWaitlist">
              <BookingWaitlistSuccess toggleSidebar={toggleBookingSidebar} />
            </BookingStep>
          )}
          {step === 'successBooking' && selectedClassId && (
            <BookingStep key="successBooking">
              <BookingSuccess classId={selectedClassId} selectedSpots={selectedSpots} />
            </BookingStep>
          )}
        </AnimatePresence>
      </ContentContainer>
    </Sidebar>
  );
};

type Queries = {
  classId?: string;
  sharedRide?: string;
};
