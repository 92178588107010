import React from 'react';
import dayjs from 'dayjs';

import { isSameDay } from 'services/date';
import { useBookingSidebar, useIntersection } from 'services/hooks';

import { BookingDay } from '../BookingDay';
import { Container, LeftArrowContainer, RightArrowContainer, Wrapper, ArrowIcon } from './styled';

const DAYS_TO_SHOW = 15;

export const BookingDaySelector = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const firstElementRef = React.useRef<HTMLDivElement>(null);
  const lastElementRef = React.useRef<HTMLDivElement>(null);
  const { activeDate, setActiveDate } = useBookingSidebar();
  const currentDay = dayjs();

  const days = Array.from(Array(DAYS_TO_SHOW).keys()).map((index) =>
    currentDay.add(index, 'day').toString(),
  );
  const firstDateIsVisble = useIntersection(firstElementRef, 0.5);
  const lastDateIsVisble = useIntersection(lastElementRef, 0.5);

  const scrollToStart = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ left: -1000, behavior: 'smooth' });
    }
  };

  const scrollToEnd = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ left: 1000, behavior: 'smooth' });
    }
  };

  return (
    <Wrapper>
      {!firstDateIsVisble && (
        <LeftArrowContainer>
          <ArrowIcon onClick={scrollToStart} $isLeft />
        </LeftArrowContainer>
      )}
      <Container ref={containerRef}>
        {days.map((date, index) => (
          <div
            ref={index === 0 ? firstElementRef : index === 14 ? lastElementRef : undefined}
            key={index}
          >
            <BookingDay
              date={date}
              onSelectDate={(date) => setActiveDate(date)}
              isActive={!!activeDate && isSameDay(date, activeDate)}
            />
          </div>
        ))}
      </Container>
      {!lastDateIsVisble && (
        <RightArrowContainer>
          <ArrowIcon onClick={scrollToEnd} />
        </RightArrowContainer>
      )}
    </Wrapper>
  );
};
