import * as React from 'react';
import { useTranslation } from 'next-i18next';

import { useGetClass } from 'queries/classes';
import { formatLongDayMonth, formatTime } from 'services/date';
import { useBookingSidebar, useInstructorThumbnail, useSize } from 'services/hooks';
import CyclingIcon from 'vectors/internal/cycling.svg';
import { Button, StyledLink } from 'common/interaction';
import { BlurredCirclesBackground, LocationPin } from 'common/layout';

import {
  ContentContainer,
  ExtendedText,
  BottomContainer,
  TopContainer,
  ContentWrapper,
  ExtendedHeading,
  WhiteBlock,
  ImageContainer,
  Image,
  ExtendedWhiteBlockText,
  ExtendedBigShoulderText,
  LocationContainer,
  ButtonContainer,
  StyledCloseIcon,
} from './styled';

export const BookFriendsRide: React.FC<BookFriendsRideProps> = ({ classId, bikeNumber }) => {
  const { t } = useTranslation();
  const { toggleBookingSidebar, handleSelectClass } = useBookingSidebar();
  const rocycleClass = useGetClass(classId);
  const instructorThumbnail = useInstructorThumbnail({
    id: rocycleClass.data?.classDetails.instructorId,
    name: rocycleClass.data?.classDetails.instructorName,
    size: 300,
  });

  const target = React.useRef<HTMLDivElement | null>(null);
  const [width, height] = useSize(target);

  if (!rocycleClass.data) return null;

  const { classDetails } = rocycleClass.data;
  const onOpenBookingSidebar = () => {
    handleSelectClass(classDetails);
    toggleBookingSidebar();
  };

  return (
    <ContentWrapper ref={target}>
      <BlurredCirclesBackground width={width} height={height}>
        <ContentContainer>
          <TopContainer>
            <ExtendedHeading as="h3">{t('Join the ride!')}</ExtendedHeading>
          </TopContainer>
          <BottomContainer>
            <WhiteBlock>
              <StyledLink href="/">
                <StyledCloseIcon />
              </StyledLink>
              <ImageContainer>
                <Image {...instructorThumbnail} />
              </ImageContainer>
              <ExtendedWhiteBlockText>
                {formatLongDayMonth(classDetails.classDate)}, {formatTime(classDetails.classDate)}
              </ExtendedWhiteBlockText>
              <ExtendedBigShoulderText>
                {t('{{ classType }} with {{ instructorName }}', {
                  classType: classDetails.classType,
                  instructorName: classDetails.instructorName,
                })}
              </ExtendedBigShoulderText>
              <LocationContainer>
                <ExtendedWhiteBlockText $isBold>
                  <LocationPin />
                  {classDetails.site}
                </ExtendedWhiteBlockText>
                <ExtendedText>{t('Your friend is riding on')}</ExtendedText>
                <ExtendedWhiteBlockText $isBold>
                  <CyclingIcon />
                  {t('Bike {{ spotNumber }}', { spotNumber: bikeNumber })}
                </ExtendedWhiteBlockText>
                <ButtonContainer>
                  <Button onClick={onOpenBookingSidebar}>{t('Select your bike')}</Button>
                </ButtonContainer>
              </LocationContainer>
            </WhiteBlock>
          </BottomContainer>
        </ContentContainer>
      </BlurredCirclesBackground>
    </ContentWrapper>
  );
};

type BookFriendsRideProps = {
  bikeNumber: string;
  classId: string;
};
