import * as i from 'types';
import React from 'react';

import { Container, ImageContainer, Image } from './styled';

export const SpotInstructor = React.forwardRef<HTMLDivElement, SpotInstructorProps>(
  ({ thumbnail, position }, ref) => {
    return (
      <Container position={position} ref={ref}>
        <ImageContainer>
          <Image {...thumbnail} />
        </ImageContainer>
      </Container>
    );
  },
);

type SpotInstructorProps = {
  thumbnail: {
    image: i.TypeImageWithFocalPointFields | undefined;
    fallback: string;
    alt: string;
  };
  position: i.StudioLayoutPosition;
};
