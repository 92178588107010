import * as i from 'types';
import * as React from 'react';
import { useTranslation } from 'next-i18next';

import { useInstructorThumbnail } from 'hooks';
import { formatLongDayMonth, formatTime } from 'services/date';
import CyclingIcon from 'vectors/internal/cycling.svg';
import { LocationPin } from 'common/layout';

import {
  WhiteBlock,
  ImageContainer,
  Image,
  ExtendedWhiteBlockText,
  ExtendedBigShoulderText,
  LocationContainer,
} from './styled';

export const RideCard: React.FC<RideCardProps> = ({ classDetails, selectedSpots, small }) => {
  const { t } = useTranslation();
  const instructorThumbnail = useInstructorThumbnail({
    id: classDetails.instructorId,
    name: classDetails.instructorName,
    size: 200,
  });

  if (!classDetails) return null;

  return (
    <WhiteBlock small={small}>
      <ImageContainer>
        <Image {...instructorThumbnail} />
      </ImageContainer>
      <ExtendedWhiteBlockText>
        {formatLongDayMonth(classDetails.classDate)}, {formatTime(classDetails.classDate)}
      </ExtendedWhiteBlockText>
      <ExtendedBigShoulderText>
        {t('{{ classType }} with {{ instructorName }}', {
          classType: classDetails.classType,
          instructorName: classDetails.instructorName,
        })}
      </ExtendedBigShoulderText>
      <LocationContainer>
        <ExtendedWhiteBlockText $isBold>
          <LocationPin />
          {classDetails.site || classDetails.siteName}
        </ExtendedWhiteBlockText>
        {selectedSpots && selectedSpots.length > 0 && classDetails.status === 'Enrolled' && (
          <ExtendedWhiteBlockText $isBold>
            <CyclingIcon />
            {t('Bike {{ spotNumber }}', { spotNumber: selectedSpots.join(', ') })}
          </ExtendedWhiteBlockText>
        )}
        {classDetails.status === 'Waitlisted' && (
          <ExtendedWhiteBlockText $isBold>{t('Waiting list')}</ExtendedWhiteBlockText>
        )}
      </LocationContainer>
    </WhiteBlock>
  );
};

type RideCardProps = {
  classDetails: i.MyClass;
  selectedSpots?: string[];
  small?: boolean;
};
