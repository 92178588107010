import * as i from 'types';
import styled, { css } from 'styled-components';

import NoiseImage from 'images/texture/grain.png';
import { media } from 'styles/utils';
import { Headline2 } from 'common/typography';

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 0;
  opacity: 1;
  transition: opacity 0.2s ease;

  ${media.desktop`
    opacity: 0;
  `}

  > span {
    margin: 0 8px 8px 0;
  }
`;

export const CallToActionText = styled.p`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  font-size: 14px;
  line-height: 100%;
  text-transform: uppercase;
  text-align: center;
  font-feature-settings: 'ss04' on;
  color: ${({ theme }) => theme.colors.white};
  transition: opacity 0.2s ease;

  ${media.desktop`
    opacity: 0;
    bottom: 40px;
  `}
`;

export const TileContainer = styled.div<TileContainerProps>`
  width: 100%;
  padding-top: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.springWood};
  background-size: 800px 800px;
  background-position-x: ${({ texture }) => texture?.x};
  background-position-y: ${({ texture }) => texture?.y};
  transition: background-position 5s ease;

  ${({ texture }) =>
    texture &&
    css`
      background-image: url(${texture?.url});
    `}
`;

type TileContainerProps = {
  texture?: i.RandomTextureWithPositions;
};

export const Container = styled.div<ContainerProps>`
  ${({ as }) =>
    as === 'button' &&
    css`
      width: 100%;
      height: 100%;
      cursor: pointer;
      appearance: none;
      padding: 0;
      border: none;
      background-color: transparent;
    `};

  &:hover ${TagContainer}, &:hover ${CallToActionText} {
    opacity: 1;
  }

  &:hover ${TileContainer} {
    background-position: 0% 0% !important;
  }
`;

type ContainerProps = {
  as?: 'button';
};

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const StyledHeadline = styled(Headline2).attrs(() => ({
  as: 'h3',
  variant: 'bigShoulders',
  size: 'm',
}))<StyledHeadlineProps>`
  width: 265px;
  font-size: 36px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  margin: 0 0 20px;

  ${({ $hyphens }) =>
    $hyphens &&
    css`
      hyphens: auto;
    `};

  ${media.desktop`
    font-size: 48px;
  `}
`;

type StyledHeadlineProps = {
  $hyphens?: boolean;
};

export const Price = styled.p`
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  font-feature-settings: 'ss04' on;
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
`;

// Don't use blend-mode-overlay here, it doesn't work in
// Chrome which is a known bug. Maybe check in newer version someday!
// @see https://stackoverflow.com/questions/66204563/backdrop-filter-not-working-when-mix-blend-mode-of-another-element-on-the-page-i
export const NoisePattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-repeat: repeat;
  background-image: url(${NoiseImage.src});
  opacity: 0.2;
`;
