import * as i from 'types';
import React from 'react';
import { useTranslation } from 'next-i18next';

import { useBookingSidebar } from 'hooks';
import { filterClassSubFilters } from 'services/filterScheduleUtils';
import ClockSvg from 'vectors/internal/clock.svg';
import CyclingSvg from 'vectors/internal/cycling.svg';
import FilterSvg from 'vectors/internal/filter.svg';
import InstructorSvg from 'vectors/internal/user.svg';
import { Button } from 'common/interaction';
import { LocationPin } from 'common/layout';

import { BookingSubFilter } from '../BookingSubFilter';
import { filterAnimation } from './animation';
import {
  OpenFilterButton,
  FilterMenu,
  Title,
  FilterContainer,
  ButtonContainer,
  Indicator,
} from './styled';

export const BookingFilter: React.FC<BookingFilterProps> = ({ classes }) => {
  const { t } = useTranslation();
  const {
    filterOpen,
    setFilterOpen,
    filteredClasses,
    activeFilters,
    setActiveFilters,
    resetActiveFilters,
  } = useBookingSidebar();

  // The sub filters are based on the full data set; (all the classes)
  const subFilters = filterClassSubFilters(classes);

  const activeFiltersCount =
    activeFilters.time.length +
    activeFilters.studios.length +
    activeFilters.type.length +
    activeFilters.instructor.length;

  const timeSubFilters = [
    { key: 'morning', label: t('filters.morning-label') },
    { key: 'afternoon', label: t('filters.afternoon-label') },
    { key: 'evening', label: t('filters.evening-label') },
  ];

  const [subFilterOpen, setSubFilterOpen] = React.useState<keyof i.ClassesFilters | undefined>(
    undefined,
  );

  const toggleSubFilterOpen = (filter: keyof i.ClassesFilters) => {
    setSubFilterOpen(filter !== subFilterOpen ? filter : undefined);
  };

  const toggleFilter = (
    filter: keyof i.ClassesFilters,
    subFilter: string,
    singleFilter: boolean,
  ) => {
    const newFilters = activeFilters[filter].includes(subFilter)
      ? activeFilters[filter].filter((activeFilter) => activeFilter !== subFilter)
      : singleFilter
      ? [subFilter]
      : [...activeFilters[filter], subFilter];

    const classesFilters = {
      ...activeFilters,
      [filter]: newFilters,
    };

    setActiveFilters(classesFilters);
  };

  return (
    <>
      <OpenFilterButton onClick={() => setFilterOpen(true)}>
        <FilterSvg /> {t('filters.button')}
        <Indicator $size={12} $weight={600} $color="gray" as="span">
          {activeFiltersCount > 0 && activeFiltersCount}
        </Indicator>
      </OpenFilterButton>
      <FilterMenu animate={filterOpen ? 'open' : 'closed'} {...{ ...filterAnimation }}>
        <Title>{t('filters.title')}</Title>
        <FilterContainer>
          <BookingSubFilter
            identifier="time"
            title={t('filters.time-label')}
            icon={<ClockSvg />}
            filters={timeSubFilters}
            activeFilters={activeFilters.time}
            {...{ subFilterOpen, toggleSubFilterOpen, toggleFilter }}
          />
          <BookingSubFilter
            identifier="type"
            title={t('filters.type-label')}
            icon={<CyclingSvg />}
            filters={subFilters.type}
            activeFilters={activeFilters.type}
            {...{ subFilterOpen, toggleSubFilterOpen, toggleFilter }}
          />
          <BookingSubFilter
            identifier="instructor"
            title={t('filters.instructor-label')}
            icon={<InstructorSvg />}
            filters={subFilters.instructor}
            activeFilters={activeFilters.instructor}
            {...{ subFilterOpen, toggleSubFilterOpen, toggleFilter }}
          />
          <BookingSubFilter
            identifier="studios"
            title={t('filters.studios-label')}
            icon={<LocationPin />}
            filters={subFilters.studios}
            activeFilters={activeFilters.studios}
            {...{ subFilterOpen, toggleSubFilterOpen, toggleFilter }}
          />
        </FilterContainer>
        <ButtonContainer>
          <Button type="button" size="fullWidth" onClick={() => setFilterOpen(false)}>
            {activeFiltersCount > 0
              ? t('filters.amount', { count: filteredClasses?.length || 0 })
              : t('filters.all')}
          </Button>
          <Button type="button" size="fullWidth" variant="secondary" onClick={resetActiveFilters}>
            {t('filters.clear')}
          </Button>
        </ButtonContainer>
      </FilterMenu>
    </>
  );
};

type BookingFilterProps = {
  classes?: i.ExtendedClass[];
};
