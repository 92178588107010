import styled from 'styled-components';

export const Block = styled.div<BlockProps>`
  width: 100%;
  height: 100vh;
  position: relative;
  padding: ${({ padding }) => padding || '0'};
  background-color: ${({ theme }) => theme.colors.white};
  overflow: ${({ overflow }) => overflow || 'visible'};
`;

type BlockProps = {
  padding?: string;
  overflow?: string;
};
