import styled, { css } from 'styled-components';

import { hexToRgba } from 'services/style';
import { media } from 'styles/utils';

export const NewsletterContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const NewsletterForm = styled.form<NewsletterFormProps>`
  display: flex;
  width: 100%;
  margin: 0 0 32px;
  position: relative;
  border-bottom: 2px solid ${({ theme }) => hexToRgba(theme.colors.text, '0.2')};
  transition: border-color 0.3s ease;

  :focus {
    outline: none;
  }

  &:hover,
  &:active,
  &:focus {
    border-bottom: 2px solid ${({ theme }) => theme.colors.text};
  }

  ${({ isSuccess, isValid, isFocused }) =>
    (isSuccess || isValid || isFocused) &&
    css`
      border-bottom: 2px solid ${({ theme }) => theme.colors.text};
    `}

  ${media.desktop`
    max-width: 324px;
    margin: 0 16px 0 0;
  `}
`;

type NewsletterFormProps = {
  isFocused: boolean;
  isValid: boolean;
  isSuccess: boolean;
};

export const NewsletterFieldWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const NewsletterEmailField = styled.input`
  flex: 1;
  padding: 11px 32px 8px 0;
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  font-size: 14px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text};
  background-color: transparent;
  border: none;

  ::placeholder {
    color: ${({ theme }) => theme.colors.text};
    transition: opacity 0.3s ease;
  }

  :focus {
    outline: none;

    ::placeholder {
      opacity: 0.4;
    }
  }
`;

export const NewsletterButton = styled.button<NewsletterButtonProps>`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 6px 4px 4px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  transition: opacity 0.3s ease;

  :focus {
    outline: none;
  }

  ${({ isSuccess }) =>
    isSuccess &&
    css`
      opacity: 1;
    `}

  ${({ isValid }) =>
    !isValid &&
    css`
      cursor: default;
      opacity: 0.4;

      :hover,
      :focus,
      :active {
        opacity: 0.4;
      }
    `}
`;

type NewsletterButtonProps = {
  isValid: boolean;
  isSuccess: boolean;
};
