import styled from 'styled-components';

import InstagramSvg from 'vectors/internal/instagram.svg';

export const SocialMenuBarContainer = styled.div`
  display: flex;
  align-items: center;

  a {
    margin-right: 33px;
  }
`;

export const InstagramIcon = styled(InstagramSvg)`
  fill: ${(props) => props.theme.colors.black};
`;
