import styled from 'styled-components';

export const BlurredTextIcon = styled.div`
  width: 54px;
  height: 54px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.springWood};
  background: ${({ theme }) => `linear-gradient(to top right, ${theme.colors.green.light}, ${theme.colors.purple})`};
  font-family: ${({ theme }) => theme.fonts.bigShouldersDisplay};
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.01em;
  font-feature-settings: 'ss04' on;
  color: ${({ theme }) => theme.colors.white};
`;
