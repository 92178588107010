import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import ArrowRight from 'vectors/internal/arrow-right.svg';
import { Tag } from 'common/layout';
import { Text } from 'common/typography';

export const UpcomingRidesList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 45px;
  padding-top: 45px;

  ${media.desktop`
    gap: 42px;
    padding-top: 42px;
  `}
`;

export const LocationTag = styled(Tag)`
  font-size: 14px;
  max-width: fit-content;
  font-family: ${(props) => props.theme.fonts.favoritPro};
  color: ${(props) => props.theme.colors.black};
`;

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: grid;
  grid-template-columns: 20% auto 5%;
  text-decoration: none;
  color: ${(props) => props.theme.colors.text};
  cursor: pointer;

  ${media.tablet`
    grid-template-columns: 20% auto 20%;
  `}

  ${({ hasThumbnail }) =>
    hasThumbnail &&
    css`
      grid-template-columns: 15% 20% auto 5%;

      ${media.tablet`
      grid-template-columns: 10% 20% auto 5%;
    `}
    `}

  ${({ hideImageOnMobile }) =>
    hideImageOnMobile &&
    css`
      grid-template-columns: 15% auto 5%;

      ${media.tablet`
      grid-template-columns: 10% 20% auto 5%;
    `}
    `}
`;

type ContainerProps = {
  hasThumbnail?: boolean;
  hideImageOnMobile?: boolean;
};

export const Title = styled(Text)`
  font-size: 20px;
  font-family: ${(props) => props.theme.fonts.favoritPro};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`;

export const ImageContainer = styled.div<ImageContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ hideOnMobile }) =>
    hideOnMobile &&
    css`
      display: none;

      ${media.tablet`
      display: flex;
    `}
    `}
`;

type ImageContainerProps = {
  hideOnMobile?: boolean;
};

export const RideImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
  background-color: ${({ theme }) => theme.colors.silver};
`;

export const DateText = styled(Text)`
  font-family: ${(props) => props.theme.fonts.bigShouldersDisplay};
  font-size: 18px;
  text-transform: uppercase;
  line-height: 18px;
  letter-spacing: 0.01em;
  margin-bottom: 5px;
  font-weight: 600;
  opacity: 0.6;
`;

export const TimeText = styled(Text)`
  font-size: 18px;
  line-height: 18px;
  font-family: ${(props) => props.theme.fonts.bigShouldersDisplay};
`;

export const ArrowIcon = styled(ArrowRight)`
  height: 24px;
  width: 24px;
  cursor: pointer;
  align-self: center;
  display: block;
`;

export const ArrowContainer = styled.div`
  ${media.desktop`
    align-self: center;
  `}
`;

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -4px;

  ${LocationTag} {
    margin: 0 4px 4px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
