import { motion } from 'framer-motion';
import styled from 'styled-components';

export const BookingStep = styled(motion.div).attrs(() => ({
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -50 },
  transition: { type: 'spring', duration: 0.3, bounce: 0 },
}))`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
