import { motion } from 'framer-motion';
import styled from 'styled-components';
import { media } from 'styles/utils';

export const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  ${media.desktop`
    align-items: center;
  `}
`;

export const StyledModal = styled.div`
  position: relative;
  z-index: 2;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const CloseButton = styled.button`
  display: block;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s ease;

  &:hover, &:active {
    opacity: 1;
  }
`;
