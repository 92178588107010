import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { ContentBlockContainer } from 'common/layout';

const Container = styled.div<ContainerProps>`
  display: grid;
  max-width: 100vw;
  grid-template-columns: 100%;

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${(props) => props.theme.colors.beige.light};
    `}

  ${media.desktop`
    grid-template-columns: 50% 50%;
  `}
`;

export const Content = styled(motion.div)<ContentProps>`
  position: relative;
  grid-column-start: ${({ $first }) => ($first ? '1' : '2')};
  overflow-x: hidden;

  ${media.desktop`
    min-height: 100vh;
  `}

  ${({ $centerContent }) =>
    $centerContent &&
    css`
      ${ContentBlockContainer} {
        display: flex;
        height: 75vh;
        padding: 50px 20px;
        flex-direction: column;
        background-color: initial;
        justify-content: center;

        ${media.desktop`
          padding: 150px 100px;
          height: 100vh;
        `}

        ${media.large`
          max-width: 75%;
          margin: 0 auto;
        `}
      }
    `}

  ${({ $fixed, $first }) =>
    $fixed &&
    css`
      position: fixed;
      top: 0;
      left: ${$first ? '0' : '50%'};
      right: ${!$first ? '0' : '50%'};
      bottom: 0;
    `}

  ${({ $fixedOnDesktop, $first }) =>
    $fixedOnDesktop &&
    css`
      ${media.desktop`
      position: fixed;
      top: 0;
      left: ${$first ? '0' : '50%'};
      right: ${!$first ? '0' : '50%'};
      bottom: 0;
    `}
    `}

  ${({ $firstOnMobile }) =>
    $firstOnMobile &&
    css`
      order: -1;
    `}

  ${({ $hideOnMobile }) =>
    $hideOnMobile &&
    css`
      display: none;

      ${media.desktop`
      display: block;
    `}
    `}

  ${({ $hideOnMobile, $first }) =>
    !$hideOnMobile &&
    css`
      grid-column-start: span 2;

      ${media.desktop`
        grid-column-start: ${$first ? '1' : '2'};
      `}
    `}

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      z-index: 2;
      background-color: ${({ theme }) => theme.colors.beige.light};
    `};
`;

type ContentProps = {
  $first?: boolean;
  $fixed?: boolean;
  $hideOnMobile?: boolean;
  $fixedOnDesktop?: boolean;
  $backgroundColor?: boolean;
  $firstOnMobile?: boolean;
  $centerContent?: boolean;
};

type ContainerProps = {
  $backgroundColor?: boolean;
};

export const Split = {
  Container,
  Content,
};
