import React from 'react';

import { useRandomTexture } from 'hooks/useRandomTexture';

import {
  Container,
  TileContainer,
  Content,
  NoisePattern,
  TagContainer,
  CallToActionText,
  StyledHeadline,
} from './styled';

const TextureTileContainer: React.FC<TextureTileProps> = ({
  onClick,
  tag = null,
  includeNoisePattern,
  children,
}) => {
  const texture = useRandomTexture();

  return (
    <Container
      {...(onClick && {
        onClick,
        as: 'button',
      })}
    >
      <TileContainer texture={texture}>
        {includeNoisePattern && <NoisePattern />}
        <Content>{children}</Content>
      </TileContainer>
      {tag && <TagContainer>{tag}</TagContainer>}
    </Container>
  );
};

type TextureTileProps = {
  onClick?: () => void;
  tag?: React.ReactNode;
  includeNoisePattern?: boolean;
  children: React.ReactNode;
};

export const TextureTile = {
  Container: TextureTileContainer,
  CallToAction: CallToActionText,
  Tag: TagContainer,
  Headline: StyledHeadline,
};
