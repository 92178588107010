import * as i from 'types';
import * as React from 'react';
import { Trans, useTranslation } from 'next-i18next';

import { useGetClass } from 'queries/classes';
import { useGetContentfulStudio } from 'queries/contentful/studios';
import { ScrollIndicator } from 'common/general';

import { SpotMap } from '../SpotMap';
import {
  Container,
  HeaderContainer,
  Title,
  ButtonContainer,
  ExtendedButton,
  ScrollIndicatorContainer,
} from './styled';

export const BookingMap: React.FC<BookingMapProps> = ({
  classId,
  selectedSpots,
  onSelectSpot,
  onSubmit,
}) => {
  const { t, i18n } = useTranslation();
  const rocycleClass = useGetClass(classId);
  const contentfulStudio = useGetContentfulStudio(i18n.language, {
    zingfitId: rocycleClass.data?.classDetails.siteId,
  });

  if (!contentfulStudio.data || !rocycleClass.data) return null;

  return (
    <Container>
      <HeaderContainer>
        <Title>
          <Trans i18nKey="booking.selectSpot.title">
            <strong>Where</strong> would you want to ride?
          </Trans>
        </Title>
      </HeaderContainer>
      <ScrollIndicatorContainer>
        <ScrollIndicator />
      </ScrollIndicatorContainer>
      <SpotMap
        layout={contentfulStudio.data.fields.spotsLayout}
        rocycleClass={rocycleClass.data}
        onSelectSpot={onSelectSpot}
      />
      <ButtonContainer>
        <ExtendedButton
          onClick={onSubmit}
          disabled={selectedSpots.length === 0 || selectedSpots.length > 5}
        >
          {t('Confirm bike', { count: selectedSpots.length })}
        </ExtendedButton>
      </ButtonContainer>
    </Container>
  );
};

type BookingMapProps = {
  classId: string;
  selectedSpots: i.Spot[];
  onSelectSpot: (spot: i.Spot) => void;
  onSubmit: () => void;
};
