import styled from 'styled-components';

import { media } from 'styles/utils';
import { StyledLink } from 'common/interaction';

export const MenuContainer = styled.nav`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  ${media.desktop`
    flex-direction: row;
    align-items: center;
  `}
`;

export const MenuLink = styled(StyledLink)`
  display: block;
  margin: 0 20px 4px 0;

  ${media.desktop`
    width: auto;
    margin: 0 16px 0 0;
  `}
`;
