import * as i from 'types';
import { useTranslation, Trans } from 'next-i18next';
import { motion } from 'framer-motion';

import { useGetScheduleClasses } from 'queries/classes';
import { useBookingSidebar, useCurrentLocation, useLocationSidebar } from 'services/hooks';

import { BookingFilter, ScheduleItem, BookingDaySelector } from '../';
import {
  ContentContainer,
  HeaderContainer,
  Title,
  ListContainer,
  EmptyStateContainer,
  EmptyStateText,
  ChangeCity,
} from './styled';

const item = {
  hidden: { opacity: 0 },
  show: (index: number) => ({ opacity: 1, transition: { delay: index * 0.03 } }),
};

export const Schedule: React.FC<ScheduleProps> = ({ onSelectClass }) => {
  const { t } = useTranslation();
  const { currentLocation } = useCurrentLocation();
  const { toggleLocationSidebar } = useLocationSidebar();
  const classes = useGetScheduleClasses(currentLocation);
  const { filteredClasses } = useBookingSidebar();

  return (
    <ContentContainer>
      <HeaderContainer>
        <Title>
          <Trans i18nKey="booking.header.title" values={{ city: currentLocation?.slug }}>
            Upcoming rides in{' '}
            <ChangeCity onClick={toggleLocationSidebar}>
              {/* @ts-ignore next-i18next type issue */}
              {{ city: currentLocation?.slug }}
            </ChangeCity>
          </Trans>
          <ChangeCity onClick={toggleLocationSidebar}>{currentLocation?.name}</ChangeCity>
        </Title>
        <BookingFilter classes={classes.data} />
      </HeaderContainer>
      <BookingDaySelector />
      <ListContainer>
        {filteredClasses && filteredClasses.length > 0 ? (
          filteredClasses.map((scheduleClass, index) => (
            <motion.div
              custom={index}
              key={scheduleClass.id}
              variants={item}
              initial="hidden"
              animate="show"
              exit="hidden"
            >
              <ScheduleItem item={scheduleClass} onSelect={onSelectClass} />
            </motion.div>
          ))
        ) : (
          <EmptyStateContainer
            custom={0}
            variants={item}
            initial="hidden"
            animate="show"
            exit="hidden"
          >
            <EmptyStateText>
              {classes.isLoading && t('Loading...')}
              {classes.isError && t('message.error.default')}
              {classes.isSuccess &&
                filteredClasses &&
                filteredClasses.length === 0 &&
                t('There are no rides on this day.')}
            </EmptyStateText>
          </EmptyStateContainer>
        )}
      </ListContainer>
    </ContentContainer>
  );
};

type ScheduleProps = {
  onSelectClass: (item: i.ExtendedClass) => void;
};
