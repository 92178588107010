import { motion } from 'framer-motion';
import styled from 'styled-components';

import { hexToRgba } from 'services/style';
import { media } from 'styles/utils';
import Close from 'vectors/internal/close.svg';
import Logo from 'vectors/internal/logo.svg';
import { StyledLink, LinkStyles } from 'common/interaction';

export const MobileNavigationContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.beige};
  z-index: 4;
  width: 100%;
  height: 100%;
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;

  ${media.desktop`
    display: none;
  `}
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100%;
  padding-left: 20px;
  justify-content: center;

  > * {
    margin-bottom: 24px;
  }
`;

export const MobileNavigationFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
  height: 25%;
  background-color: ${({ theme }) => theme.colors.beige};

  > * {
    margin-bottom: 24px;
  }
`;

export const MobileNavigationHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.beige};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid ${({ theme }) => hexToRgba(theme.colors.text, '0.2')};
  padding: 10px 20px;
`;

export const HeaderMenuLogoIcon = styled(Logo)`
  height: 19px;
`;

export const HeaderMenuCloseIcon = styled(Close)`
  height: 24px;
  margin-right: 19px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 33.33%;
`;

export const MenuItem = styled(StyledLink)`
  font-size: 24px;
`;

export const MenuButton = styled.button`
  ${LinkStyles}
`;
