import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ExtendedMotionDiv = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  overflow: hidden;
`;
