import styled from 'styled-components';
import { motion } from 'framer-motion';

import { hexToRgba } from 'services/style';
import { Headline3 } from 'common/typography';

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 30px 0;
`;

export const EmptyStateContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 25px 20px;
`;

export const EmptyStateText = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  font-size: 20px;
  font-weight: normal;
  line-height: 120%;
  letter-spacing: -0.03em;
  font-feature-settings: 'ordn' on, 'ss04' on, 'ss06' on, 'liga' off;
  color: ${({ theme }) => hexToRgba(theme.colors.black, '60%')};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
`;

export const HeaderContainer = styled.div`
  padding: 120px 20px 100px;
`;

export const Title = styled(Headline3)`
  text-align: center;
`;

export const ChangeCity = styled.u`
  cursor: pointer;
`;
