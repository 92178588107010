import * as i from 'types';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { sortBy } from 'lodash';

import { LocationListItem } from '../';
import { Container, CityTitle } from './styled';

export const LocationList: React.FC<LocationListProps> = ({ locations }) => {
  const { i18n } = useTranslation();

  const locationsInCountries = locations.reduce((acc, location) => {
    const key = location.country as string;
    if (!acc[key]) acc[key] = [];

    acc[key].push(location);

    return acc;
  }, {} as LocationsInCountriesType);

  const sortCountries = (countries: string[]) => {
    // If we're on the german site, we want germany at the top.
    // so language === 'de' shows 'Germany' and language === 'en' shows 'The Netherlands'
    // If 'Germany' country name in 'de' website changes in the CMS, change here.
    const firstCountry = i18n.language === 'de' ? 'Deutschland' : 'The Netherlands';
    return [firstCountry, ...countries.filter((country: string) => country !== firstCountry)];
  };

  return (
    <>
      {sortCountries(Object.keys(locationsInCountries)).map((key) => (
        <React.Fragment key={key}>
          <CityTitle>{key}</CityTitle>
          <Container>
            {sortBy(locationsInCountries[key], 'title').map((location) => (
              <LocationListItem location={location} key={location.slug} />
            ))}
          </Container>
        </React.Fragment>
      ))}
    </>
  );
};

type LocationListProps = {
  locations: i.HeaderLocation[];
};

type LocationsInCountriesType = Record<string, i.HeaderLocation[]>;
