import styled, { css } from 'styled-components';

import { hexToRgba } from 'services/style';
import ArrowSvg from 'vectors/internal/arrow-right.svg';

export const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 25px 20px 25px 30px;
  background-color: transparent;
  transition: background-color 0.3s;

  ${({ onClick }) =>
    !!onClick &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => hexToRgba(theme.colors.villa, '0.6')};
      }
    `}

  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      background-color: ${hexToRgba(theme.colors.villa, '0.8')};

      &:hover {
        background-color: ${theme.colors.villa};
      }
    `}
`;

type ContainerProps = {
  $isSelected?: boolean;
  onClick?: () => void;
};

export const CreditCountContainer = styled.div`
  position: relative;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.bigShouldersDisplay};
  font-weight: normal;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.01em;
  margin-right: 20px;
  flex-shrink: 0;
`;

export const Details = styled.div`
  flex: 1;
`;

export const Title = styled.p`
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  font-size: 20px;
  font-weight: normal;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: ${({ theme }) => theme.colors.text};
  margin: 0 0 8px;
`;

export const TagContainer = styled.div`
  display: flex;

  > span:not(:first-child) {
    margin-left: 5px;
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowIcon = styled(ArrowSvg)`
  width: 32px;
  height: auto;
`;
