import { MotionProps } from 'framer-motion';

export const filterAnimation: MotionProps = {
  initial: 'closed',
  variants: {
    open: {
      display: 'flex',
      opacity: 1,
      y: '0%',
    },
    closed: {
      opacity: 0,
      y: '100%',
      transitionEnd: {
        display: 'none',
      },
    },
  },
  transition: {
    type: 'spring',
    duration: 0.5,
    bounce: 0,
  },
};
