import * as i from 'types';
import * as React from 'react';
import { useTranslation } from 'next-i18next';

import { useGetClass } from 'queries/classes';
import { isServer } from 'services';
import { formatLongDayMonth, formatTime } from 'services/date';
import { useInstructorThumbnail, useSize } from 'services/hooks';
import CheckIcon from 'vectors/internal/check.svg';
import CyclingIcon from 'vectors/internal/cycling.svg';
import { Button } from 'common/interaction';
import { BlurredCirclesBackground, LocationPin } from 'common/layout';

import {
  ContentContainer,
  ExtendedText,
  BottomContainer,
  TopContainer,
  ContentWrapper,
  ExtendedHeading,
  WhiteBlock,
  ImageContainer,
  Image,
  ExtendedWhiteBlockText,
  ExtendedBigShoulderText,
  LocationContainer,
  ButtonContainer,
  StyledLinkIcon,
  ButtonContent,
} from './styled';

export const BookingSuccess: React.FC<BookingSuccessProps> = ({ classId, selectedSpots }) => {
  const { t } = useTranslation();
  const rocycleClass = useGetClass(classId);
  const instructorThumbnail = useInstructorThumbnail({
    id: rocycleClass.data?.classDetails?.instructorId,
    name: rocycleClass.data?.classDetails?.instructorName,
    size: 300,
  });
  const [isSharedLinkCopied, setIsSharedLinkCopied] = React.useState(false);
  const target = React.useRef<HTMLDivElement | null>(null);
  const [width, height] = useSize(target);

  const copyClassInfoToClipBoard = (classId: string, bikeNumber: string) => {
    if (!isServer) {
      const url = new URL(`${process.env.NEXT_PUBLIC_SITE_URL}/share-ride`);
      url.searchParams.set('classId', classId);
      url.searchParams.set('bikeNumber', bikeNumber);

      navigator.clipboard.writeText(url.toString());
      setIsSharedLinkCopied(true);
    }
  };

  if (!rocycleClass.data) return null;
  const { classDetails } = rocycleClass.data;

  return (
    <ContentWrapper ref={target}>
      <BlurredCirclesBackground width={width} height={height}>
        <ContentContainer>
          <TopContainer>
            <ExtendedHeading as="h3">{t('Youre all ready to ride!')}</ExtendedHeading>
            <ExtendedText>{t('Manage your bookings in the Rocycle app!')}</ExtendedText>
          </TopContainer>
          <BottomContainer>
            <WhiteBlock>
              <ImageContainer>
                <Image {...instructorThumbnail} />
              </ImageContainer>
              <ExtendedWhiteBlockText>
                {formatLongDayMonth(classDetails.classDate)}, {formatTime(classDetails.classDate)}
              </ExtendedWhiteBlockText>
              <ExtendedBigShoulderText>
                {t('{{ classType }} with {{ instructorName }}', {
                  classType: classDetails.classType,
                  instructorName: classDetails.instructorName,
                })}
              </ExtendedBigShoulderText>
              <LocationContainer>
                <ExtendedWhiteBlockText $isBold>
                  <LocationPin />
                  {classDetails.site}
                </ExtendedWhiteBlockText>
                {selectedSpots && (
                  <ExtendedWhiteBlockText $isBold>
                    <CyclingIcon />
                    {t('Bike {{ spotNumber }}', {
                      spotNumber: selectedSpots.map((spot) => spot.label).join(', '),
                    })}
                  </ExtendedWhiteBlockText>
                )}
                <ButtonContainer>
                  {!isSharedLinkCopied ? (
                    <Button
                      onClick={() =>
                        copyClassInfoToClipBoard(classDetails.id, selectedSpots[0].label)
                      }
                      noArrow
                    >
                      <StyledLinkIcon />
                      {t('Share this ride')}
                    </Button>
                  ) : (
                    <Button disabled noArrow>
                      <ButtonContent>
                        <CheckIcon width={24} height={24} />
                        {t('Link copied')}
                      </ButtonContent>
                    </Button>
                  )}
                </ButtonContainer>
              </LocationContainer>
            </WhiteBlock>
          </BottomContainer>
        </ContentContainer>
      </BlurredCirclesBackground>
    </ContentWrapper>
  );
};

type BookingSuccessProps = {
  classId: string;
  selectedSpots: i.Spot[];
};
