import styled from 'styled-components';
import { media } from 'styles/utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 0 60px 0;
`;

export const CityTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.01em;
  font-feature-settings: 'ss04' on;
  color: ${({ theme }) => theme.colors.text};
  opacity: 0.6;
  padding: 0 0 5px 20px;
  display: block;

  ${media.desktop`
    padding: 0 0 10px 50px;
  `}
`;
