import React from 'react';

import { getRandomInt } from 'services/mathHelpers';

import { AnimationBase } from './animationBase';
import { Container, Canvas, ChildrenContainer } from './styled';

export const BlurredCirclesBackground: React.FC<BlurredCirclesBackgroundProps> = ({
  $hide,
  width,
  height,
  position = 'bottom',
  children,
}) => {
  const randomVariantRef = React.useRef(getRandomInt(0, 3));
  const [canvasRef, setCanvasRef] = React.useState<HTMLCanvasElement | null>(null);
  const requestAnimationFrameRef = React.useRef(0);

  const animation = React.useMemo(() => {
    if (!canvasRef) return null;

    return new AnimationBase(canvasRef, width, height, randomVariantRef.current, position);
  }, [canvasRef, width, height, position]);

  React.useEffect(() => {
    if (animation) {
      requestAnimationFrameRef.current = window.requestAnimationFrame(() => animation.loop());
    }

    return () => cancelAnimationFrame(requestAnimationFrameRef.current);
  }, [animation]);

  React.useEffect(() => {
    if (animation) {
      animation.resizeCanvas(width, height);
    }
  }, [animation, width, height]);

  return (
    <Container>
      {!$hide && <Canvas ref={setCanvasRef} />}
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};

type BlurredCirclesBackgroundProps = {
  $hide?: boolean;
  width: number;
  height: number;
  position?: 'top' | 'bottom';
  children: React.ReactNode;
};
