import * as i from 'types';
import React from 'react';
import { Trans, useTranslation } from 'next-i18next';

import { useInstructorThumbnail } from 'hooks';
import { getScheduleClassStatus, getStatusLabel } from 'services/class';
import { formatTime } from 'services/date';
import {
  Tag,
  ListItemContainer,
  ListItemImageContainer,
  ListItemImage,
  ListItemContentContainer,
  ListItemTitle,
  ListItemTagsContainer,
  ListItemArrowContainer,
  ListItemArrow,
  ListItemDateContainer,
  ListItemDateText,
  LocationPin,
} from 'common/layout';

export const ScheduleItem: React.FC<ScheduleItemProps> = ({ item, onSelect }) => {
  const { t } = useTranslation();
  const instructorThumbnail = useInstructorThumbnail({
    id: item.instructorId,
    name: item.instructorName,
    size: 100,
  });
  const status = getScheduleClassStatus(item);
  const statusLabel = getStatusLabel(status);
  const showStatus = ['full', 'waitlist', 'almost-full', 'cancelled'].includes(status);
  const isDisabled = ['full', 'non-bookable', 'past', 'cancelled', 'closed'].includes(status);

  const handleSelect = () => {
    if (isDisabled) return;
    onSelect(item);
  };

  return (
    <ListItemContainer isDisabled={isDisabled} onClick={handleSelect}>
      <ListItemDateContainer>
        <ListItemDateText>{formatTime(item.classDate)}</ListItemDateText>
      </ListItemDateContainer>
      <ListItemImageContainer>
        <ListItemImage {...instructorThumbnail} />
      </ListItemImageContainer>
      <ListItemContentContainer>
        <ListItemTitle>
          <Trans
            i18nKey="booking.schedule.item.title"
            values={{ classType: item.classType, instructorName: item.instructorName }}
          >
            {{ classType: item.classType }} with
            {/* @ts-ignore next-i18next type issue */}
            <strong>{{ instructorName: item.instructorName }}</strong>
          </Trans>
          <ListItemArrow $small />
        </ListItemTitle>
        <ListItemTagsContainer>
          <Tag small>
            <LocationPin />
            {item.site}
          </Tag>
          {showStatus && statusLabel && (
            <Tag small highlighted>
              {t(statusLabel)}
            </Tag>
          )}
        </ListItemTagsContainer>
      </ListItemContentContainer>
      <ListItemArrowContainer>
        <ListItemArrow />
      </ListItemArrowContainer>
    </ListItemContainer>
  );
};

type ScheduleItemProps = {
  item: i.ExtendedClass;
  onSelect: (item: i.ExtendedClass) => void;
};
