import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { hexToRgba } from 'services/style';
import { media } from 'styles/utils';
import Logo from 'vectors/internal/logo.svg';
import { LinkStyles } from 'common/interaction';

export const HeaderMenuContainer = styled(motion.nav)<HeaderMenuContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 3;
  top: 0;
  height: 50px;
  padding: 0 20px;
  transition: background-color 0.3s ease;
  background-color: ${({ theme }) => hexToRgba(theme.colors.white, '0.3')};
  backdrop-filter: blur(50px);

  ${({ $hasFixedVideoHeader }) =>
    $hasFixedVideoHeader &&
    css`
      position: sticky;
      top: -1px;
    `}

  ${media.desktop`
    padding: 0 50px;
    height: 60px;
  `}
`;

type HeaderMenuContainerProps = {
  $hasFixedVideoHeader: boolean;
  $isSticky: boolean;
};

export const HeaderMenuLogoIcon = styled(Logo)`
  width: 82px;
  height: 24px;
`;

export const HeaderMenuAnimationContainer = styled.div<HeaderMenuAnimationContainerProps>`
  display: flex;
  align-items: center;
  transform: translateX(-121px);
  transition: transform 0.35s ease-in-out;

  ${({ logoVisible }) =>
    logoVisible &&
    css`
      transform: translateX(0);
    `}
`;

type HeaderMenuAnimationContainerProps = {
  logoVisible?: boolean;
};

export const HeaderMenuLinkWrapper = styled.nav`
  display: none;

  a:not(:first-child) {
    margin-left: 20px;
  }

  ${media.desktop`
    display: flex;
    margin-left: 40px;
  `}
`;

export const HeaderMenuLeftContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const HeaderMenuOverflowWrapper = styled.div`
  overflow: hidden;
`;

export const HeaderMenuSecondaryLinkWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;

  > :not(:first-child) {
    display: none;
  }

  ${media.desktop`
    > :not(:first-child) {
      display: flex;
      margin-right: 30px;
    }
  `}
`;

export const HeaderMenuLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    justify-content: center;
  }
`;

export const HeaderMenuBurgerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;

  svg {
    width: 20px;
    height: 7px;
  }

  &:hover {
    cursor: pointer;
  }

  ${media.desktop`
    display: none;
  `}
`;

export const HeaderMenuLink = styled.button`
  ${LinkStyles};
`;
