import type * as i from 'types';
import { Trans, useTranslation } from 'next-i18next';

import { useInstructorThumbnail } from 'hooks';
import { formatLongDayMonth, formatTime } from 'services/date';
import { Thumbnail } from 'common/general';

import { LocationPin } from '../LocationPin';
import {
  Container,
  ArrowContainer,
  ArrowIcon,
  LocationTag,
  ImageContainer,
  Title,
  TitleContainer,
  DateContainer,
  DateText,
  TimeText,
  UpcomingRidesList,
  TagContainer,
} from './styled';

const UpcomingRidesListItem = <T extends i.Class | i.MyClass>({
  ride,
  onClick,
  hideImageOnMobile,
}: ListItemProps<T>) => {
  const { t } = useTranslation();
  const instructorThumbnail = useInstructorThumbnail({
    id: ride.instructorId,
    name: ride.instructorName,
    size: 100,
  });

  const handleClickItem = () => {
    onClick(ride);
  };

  if (!ride) return null;

  return (
    <Container
      onClick={handleClickItem}
      hasThumbnail={!!instructorThumbnail}
      hideImageOnMobile={hideImageOnMobile}
    >
      <DateContainer>
        <DateText>{formatLongDayMonth(ride.classDate)}</DateText>
        <TimeText>{formatTime(ride.classDate)}</TimeText>
      </DateContainer>
      {instructorThumbnail && (
        <ImageContainer hideOnMobile={hideImageOnMobile}>
          <Thumbnail {...instructorThumbnail} />
        </ImageContainer>
      )}
      <TitleContainer>
        <Title>
          <Trans
            i18nKey="upcomingRides.title"
            values={{ classType: ride.classType, instructorName: ride.instructorName }}
          >
            {{ classType: ride.classType }} with
            {/* @ts-ignore next-i18next type issue */}
            <strong>{{ instructorName: ride.instructorName }}</strong>
          </Trans>
        </Title>
        <TagContainer>
          <LocationTag small>
            <LocationPin size={12} />
            {ride.site}
          </LocationTag>
          {ride.spotLabel && ride.spotLabel.length > 0 && ride.status === 'Enrolled' && (
            <LocationTag small>
              {t('Bike')}:{' '}
              {Array.isArray(ride.spotLabel) ? ride.spotLabel.join(', ') : ride.spotLabel}
            </LocationTag>
          )}
          {ride.status === 'Waitlisted' && <LocationTag small>{t('Waiting list')}</LocationTag>}
          {ride.status === 'Cancelled' && (
            <LocationTag small>{t('schedule.status.cancelled')}</LocationTag>
          )}
          {ride.status === 'No Show' && (
            <LocationTag small>{t('schedule.status.lateCancelled')}</LocationTag>
          )}
        </TagContainer>
      </TitleContainer>
      <ArrowContainer>
        <ArrowIcon />
      </ArrowContainer>
    </Container>
  );
};

type ListItemProps<T extends i.Class | i.MyClass> = {
  ride: T;
  onClick: (ride: T) => void;
  hideImageOnMobile?: boolean;
};

export const UpcomingRides = {
  List: UpcomingRidesList,
  ListItem: UpcomingRidesListItem,
};
