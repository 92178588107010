import * as i from 'types';
import * as React from 'react';
import { Trans } from 'next-i18next';

import { useGetClass } from 'queries/classes';
import { useGetClassSeries } from 'queries/series';
import { Button } from 'common/interaction';

import { BookingSeriesItem } from '../';
import { Container, HeaderContainer, Title, ContentContainer, ButtonContainer } from './styled';

export const BookingSelectSeries: React.FC<BookingSelectSeriesProps> = ({
  classId,
  selectedSpots,
  onSubmitBooking,
}) => {
  const classSeries = useGetClassSeries(classId);
  const rocycleClass = useGetClass(classId);
  const [selectedSeries, setSelectedSeries] = React.useState<i.UserSeries[]>([]);
  const creditsUsed = selectedSeries.reduce((acc, cur) => acc + cur.classesRemaining, 0);

  // User can select multiple series. Toggle the user selected series.
  const handleSelectSeries = (newSeries: i.UserSeries) => {
    let newlySelectedSeries: i.UserSeries[] = selectedSeries;

    if (selectedSeries.some((spot) => spot.id === newSeries.id)) {
      newlySelectedSeries = newlySelectedSeries.filter((spot) => spot.id !== newSeries.id);
    } else {
      newlySelectedSeries.push(newSeries);
    }

    setSelectedSeries([...newlySelectedSeries]);
  };

  return (
    <Container>
      <HeaderContainer>
        <Title>
          <Trans i18nKey="booking.selectSeries.title">
            <strong>Select the credits</strong> you'd like to spend
          </Trans>
        </Title>
      </HeaderContainer>
      <ContentContainer>
        {classSeries.data?.series.map((userSeries) => (
          <BookingSeriesItem
            key={userSeries.id}
            item={userSeries}
            isSelected={selectedSeries.some((currentSeries) => currentSeries.id === userSeries.id)}
            rocycleClass={rocycleClass.data}
            onSelect={handleSelectSeries}
          />
        ))}
      </ContentContainer>
      <ButtonContainer>
        <Button
          disabled={selectedSpots.length > creditsUsed}
          onClick={() => onSubmitBooking(selectedSeries)}
        >
          <Trans i18nKey="booking.selectSeries.submit" count={selectedSpots.length}>
            Book ride
          </Trans>
        </Button>
      </ButtonContainer>
    </Container>
  );
};

type BookingSelectSeriesProps = {
  classId: string;
  selectedSpots: i.Spot[];
  onSubmitBooking: (series: i.UserSeries[]) => void;
};
