import * as i from 'types';
import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const PlaceholderContainer = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  ${media.desktop`
    width: auto;
  `}

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Container = styled.div<ContainerProps>`
  position: relative;
  margin-right: auto;

  ${({ dimensions }) => css`
    width: ${dimensions.width}px;
    height: ${dimensions.height}px;
  `}

  ${media.tablet`
    margin: 0 auto;
  `}
`;

type ContainerProps = {
  dimensions: i.StudioLayout['dimensions']
};
