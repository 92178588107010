import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`;

export const ChildrenContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
`;
