import { MotionProps } from 'framer-motion';

export const subFilterAnimation: MotionProps = {
  initial: 'closed',
  variants: {
    open: {
      display: 'flex',
      opacity: 1,
      height: 'auto',
      marginTop: '20px',
      transition: {
        opacity: {
          duration: 0.65,
        },
      },
    },
    closed: {
      opacity: 0,
      height: 0,
      marginTop: 0,
      transitionEnd: {
        display: 'none',
      },
      transition: {
        opacity: {
          duration: 0.15,
        },
      },
    },
  },
  transition: {
    type: 'spring',
    duration: 0.5,
    bounce: 0,
  },
};
