import styled from 'styled-components';

import { media } from 'styles/utils';

export const ContentBlockContainer = styled.div`
  width: 100%;
  padding: 80px 30px 30px;
  position: relative;

  ${media.desktop`
    padding: 80px 50px 150px 50px;
  `}

  ${media.large`
    max-width: 75%;
    margin: 0 auto;
  `}
`;
