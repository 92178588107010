import * as i from 'types';
import React from 'react';
import { Trans } from 'next-i18next';

import { useCurrentLocation } from 'services/hooks';
import { Sidebar } from 'common/interaction';
import { SplitMenu } from 'common/layout';
import { SignatureBigShouldersTitle } from 'common/typography';

import { LocationList } from '../';
import { ContentContainer, BookingHeaderContainer } from './styled';

export const CurrentLocationSidebar: React.FC<CurrentLocationSidebarProps> = ({
  isVisible,
  toggleVisibility,
  locations,
}) => {
  const { currentLocation } = useCurrentLocation();

  React.useEffect(() => {
    if (isVisible) toggleVisibility();
  }, [currentLocation]);

  return (
    <Sidebar isVisible={isVisible} toggleVisibility={toggleVisibility}>
      <ContentContainer>
        <SplitMenu handleClose={toggleVisibility} showLogo />
        <BookingHeaderContainer>
          <SignatureBigShouldersTitle>
            <Trans i18nKey="locationswitch.header.title">
              <i>We are</i> all across the map
            </Trans>
          </SignatureBigShouldersTitle>
          <LocationList locations={locations} />
        </BookingHeaderContainer>
      </ContentContainer>
    </Sidebar>
  );
};

type CurrentLocationSidebarProps = {
  isVisible: boolean;
  toggleVisibility: () => void;
  locations: i.HeaderLocation[];
};
