import * as i from 'types';
import React from 'react';

import { Tag } from 'common/layout';
import { Text } from 'common/typography';

import { subFilterAnimation } from './animation';
import { SubFilter, SubFilterHeading, Arrow, FilterTags, Indicator } from './styled';

export const BookingSubFilter: React.FC<BookingSubFilterProps> = ({
  identifier,
  title,
  icon,
  activeFilters,
  filters,
  toggleFilter,
  subFilterOpen,
  toggleSubFilterOpen,
  singleFilter = false,
}) => {
  const subFilterIsOpen = subFilterOpen === identifier;

  return (
    <SubFilter>
      <SubFilterHeading onClick={() => toggleSubFilterOpen(identifier)}>
        {icon}
        <Text $size={20}>
          {title}
          <Indicator $size={12} $weight={600} $color="gray" as="span">
            {activeFilters.length > 0 && activeFilters.length}
          </Indicator>
        </Text>
        <Arrow $rotate={subFilterIsOpen}>{'->'}</Arrow>
      </SubFilterHeading>
      <FilterTags animate={subFilterIsOpen ? 'open' : 'closed'} {...{ ...subFilterAnimation }}>
        {filters.map((filter) => (
          <Tag
            key={filter.key}
            role="button"
            large
            $active={activeFilters.includes(filter.key)}
            onClick={() => toggleFilter(identifier, filter.key, singleFilter)}
          >
            {filter.label}
          </Tag>
        ))}
      </FilterTags>
    </SubFilter>
  );
};

type BookingSubFilterProps = {
  identifier: keyof i.ClassesFilters;
  title: string;
  icon: React.ReactNode;
  activeFilters: string[];
  filters: i.FilterItem[];
  toggleFilter: (filter: keyof i.ClassesFilters, subFilter: string, singleFilter: boolean) => void;
  singleFilter?: boolean;
  subFilterOpen?: keyof i.ClassesFilters;
  toggleSubFilterOpen: (filter: keyof i.ClassesFilters) => void;
};
