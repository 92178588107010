import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import ArrowIcon from 'vectors/internal/arrow-right.svg';
import { Thumbnail } from 'common/general';

export const ListItemTitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.01em;
  font-weight: normal;
  font-variation-settings: 'wght' 300, 'ital' 0;
  color: ${({ theme }) => theme.colors.text};
  margin: 0;
  display: flex;
  align-items: center;
  white-space: break-spaces;
  transition: font-variation-settings 0.3s;

  ${media.tablet`
    svg {
      display: none;
    }
  `}

  ${media.desktop`
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.03em;
  `}
`;

export const ListItemContainer = styled.button<ListItemContainerProps>`
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: transparent;
  appearance: none;
  border: none;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 10px 20px;

  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.villa};

        ${ListItemTitle} {
          font-variation-settings: 'wght' 700, 'ital' 12;
        }
      }
    `};

  ${({ hasHoverTitle }) =>
    hasHoverTitle &&
    css`
      &:hover p {
        font-variation-settings: 'wght' 700, 'ital' 12;
      }
    `}

  ${({ light }) =>
    light &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.beige.light};
      }
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;

      &:hover {
        background-color: transparent;
      }
    `}

  ${media.desktop`
    padding: 20px 50px;
  `}
`;

type ListItemContainerProps = {
  isDisabled?: boolean;
  light?: boolean;
  hasHoverTitle?: boolean;
};

export const ListItemImageContainer = styled.div`
  margin: 0 15px 0 0;
  display: none;

  ${media.tablet`
    display: block;
  `}

  ${media.desktop`
    margin: 0 20px 0 0;
  `}
`;

export const ListItemImage = styled(Thumbnail)`
  width: 56px;
  height: 56px;

  ${media.desktop`
    width: 64px;
    height: 64px;
  `}
`;

export const ListItemDateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 24px 0 0;

  ${media.desktop`
    margin: 0 27px 0 0;
  `}
`;

export const ListItemDateText = styled.time`
  font-family: ${({ theme }) => theme.fonts.bigShouldersDisplay};
  font-size: 14px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.text};

  ${media.desktop`
    font-size: 16px;
  `}
`;

export const ListItemContentContainer = styled.div`
  flex: 1;
`;

export const ListItemTagsContainer = styled.div`
  display: flex;
  flex-flow: wrap;

  > span {
    margin: 8px 5px 0 0;
  }
`;

export const ListItemArrowContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: none;

  ${media.tablet`
    display: flex;
  `}
`;

export const ListItemArrow = styled(ArrowIcon)<ListItemArrowProps>`
  width: 32px;
  height: auto;
  margin-left: 10px;

  path {
    fill: ${({ theme }) => theme.colors.black};
  }

  ${({ $small }) =>
    $small &&
    css`
      width: 20px;
    `}
`;

type ListItemArrowProps = {
  $small?: boolean;
};
