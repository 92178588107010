import * as i from 'types';
import React from 'react';
import { useTranslation } from 'next-i18next';

import { useBookingSidebar, useCurrentLocation } from 'services/hooks';
import { getRandomTexture } from 'services/textures';
import User from 'vectors/internal/user.svg';
import {
  Tag,
  ListItemImageContainer,
  ListItemImage,
  ListItemContentContainer,
  ListItemTitle,
  ListItemTagsContainer,
  ListItemArrowContainer,
  ListItemArrow,
  LocationPin,
  ListItemContainer,
} from 'common/layout';

export const LocationListItem: React.FC<LocationListItemProps> = ({ location }) => {
  const { t } = useTranslation();
  const { currentLocation, setClosestLocationToCity } = useCurrentLocation();
  const { resetActiveFilters } = useBookingSidebar();
  const isCurrentLocation = currentLocation?.slug === location.slug;

  const onSetLocation = (location: i.HeaderLocation) => {
    resetActiveFilters();
    setClosestLocationToCity(location.slug);
  };

  return (
    <ListItemContainer onClick={() => onSetLocation(location)}>
      <ListItemImageContainer>
        <ListItemImage
          fallback={getRandomTexture()}
          alt={t('City {{ cityTitle }}', { cityTitle: location.slug }) as string}
        />
      </ListItemImageContainer>
      <ListItemContentContainer>
        <ListItemTitle>
          {location.name} <ListItemArrow $small />
        </ListItemTitle>
        <ListItemTagsContainer>
          <Tag small>
            <LocationPin />
            {t('{{ count }} studio', { count: location.studios })}
          </Tag>
          <Tag small>
            <User />
            {t('{{ count }} instructor', { count: location.instructors })}
          </Tag>
          {isCurrentLocation && (
            <Tag small highlighted>
              {t("You're here!")}
            </Tag>
          )}
        </ListItemTagsContainer>
      </ListItemContentContainer>
      <ListItemArrowContainer>
        <ListItemArrow />
      </ListItemArrowContainer>
    </ListItemContainer>
  );
};

type LocationListItemProps = {
  // location: GatsbyTypes.WAGTAIL_City;
  location: i.HeaderLocation;
};
