import * as i from 'types';
import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { AnimatePresence } from 'framer-motion';

import { useBodyScrollLock, useCurrentLocation } from 'services/hooks';
import { LocationPin } from 'common/layout';

import { UserMenu } from '../UserMenu';
import {
  MobileNavigationContainer,
  MobileNavigationHeader,
  HeaderMenuLogoIcon,
  HeaderMenuCloseIcon,
  IconContainer,
  LinkContainer,
  MobileNavigationFooter,
  MenuItem,
  MenuButton,
} from './styled';

export const MenuMobile: React.FC<MenuMobileProps> = ({
  menuItems,
  menuVisible,
  callToActionButton,
  toggleMenu,
  toggleBookingSidebar,
  toggleLocationSidebar,
}) => {
  const { t } = useTranslation();
  const nearestCity = useCurrentLocation()?.currentLocation?.slug;
  const { setScrollLock } = useBodyScrollLock();

  React.useEffect(() => {
    setScrollLock(menuVisible);
  }, [menuVisible]);

  return (
    <AnimatePresence>
      {menuVisible && (
        <MobileNavigationContainer
          key="mobileNavigation"
          initial={{ opacity: 0, x: '-100%' }}
          animate={{ opacity: 1, x: '0' }}
          exit={{ opacity: 0, x: '-100%' }}
          transition={{ type: 'spring', duration: 0.5, bounce: 0 }}
        >
          <MobileNavigationHeader>
            <IconContainer>
              <HeaderMenuCloseIcon onClick={toggleMenu} />
              <HeaderMenuLogoIcon />
            </IconContainer>
            {callToActionButton ? (
              callToActionButton
            ) : (
              <MenuButton onClick={toggleBookingSidebar} $isBold>
                {t('Book a ride')}
              </MenuButton>
            )}
          </MobileNavigationHeader>
          <LinkContainer>
            {menuItems.map((menuItem) => (
              <div key={menuItem.sys.id}>
                <MenuItem
                  href={menuItem.fields.url}
                  {...(menuItem.fields.openInNewTab && {
                    target: '_blank',
                  })}
                >
                  {menuItem.fields.title}
                </MenuItem>
              </div>
            ))}
          </LinkContainer>
          <MobileNavigationFooter>
            <MenuButton onClick={toggleLocationSidebar} $withIcon>
              <LocationPin /> {nearestCity}
            </MenuButton>
            <UserMenu />
          </MobileNavigationFooter>
        </MobileNavigationContainer>
      )}
    </AnimatePresence>
  );
};

type MenuMobileProps = {
  menuItems: i.TypeNavigationItem[];
  menuVisible: boolean;
  visible: boolean;
  callToActionButton?: React.ReactElement;
  toggleMenu: () => void;
  toggleBookingSidebar: () => void;
  toggleLocationSidebar: () => void;
};
