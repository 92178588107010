import * as i from 'types';
import styled, { css } from 'styled-components';

export const ContentContainer = styled.div<ContentContainerProps>`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: none;
  background-color: transparent;
  transition: background-color 0.3s 0.3s;

  ${({ $step, theme }) => $step === 'map' && css`
    background-color: ${theme.colors.text};
  `}
`;

type ContentContainerProps = {
  $step: i.BookingSidebarSteps;
};

export const Overlay = styled.div<OverlayProps>`
  transition: opacity 300ms;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  ${({ $visible }) => $visible && css`
    z-index: 1;
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  `};
`;

type OverlayProps = {
  $visible: boolean;
};
