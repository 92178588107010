import React from 'react';

import { useCurrentLocation, useLocationSidebar } from 'services/hooks';
import { StyledLink } from 'common/interaction';

import { LocationPin } from '../LocationPin';
import {
  Logo,
  CloseButton,
  BackButton,
  LogoContainer,
  SplitMenuContainer,
  ButtonContainer,
  RightContainer,
} from './styled';

export const SplitMenu: React.FC<SplitMenuProps> = ({
  handleGoBack,
  handleClose,
  showBackButton,
  showLogo,
  isWhite,
  showLocation,
}) => {
  const { toggleLocationSidebar } = useLocationSidebar();
  const nearestCity = useCurrentLocation()?.currentLocation?.slug;

  return (
    <SplitMenuContainer>
      {showBackButton && (
        <ButtonContainer onClick={handleGoBack}>
          <BackButton $isWhite={isWhite} />
        </ButtonContainer>
      )}
      {showLogo && (
        <LogoContainer $showBackButton={showBackButton}>
          <StyledLink href="/">
            <Logo $isWhite={isWhite} />
          </StyledLink>
        </LogoContainer>
      )}
      <RightContainer>
        {showLocation && (
          <StyledLink as="button" onClick={toggleLocationSidebar} $withIcon>
            <LocationPin /> {nearestCity}
          </StyledLink>
        )}
        <ButtonContainer onClick={handleClose}>
          <CloseButton $isWhite={isWhite} />
        </ButtonContainer>
      </RightContainer>
    </SplitMenuContainer>
  );
};

type SplitMenuProps = {
  handleGoBack?: () => void;
  handleClose: () => void;
  showBackButton?: boolean;
  showLogo?: boolean;
  isWhite?: boolean;
  showLocation?: boolean;
};
