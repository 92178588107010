import * as i from 'types';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';

import api from 'services/api';
import { sendError } from 'services/sentry';
import { validation } from 'services/validation';
import ArrowRightIcon from 'vectors/internal/arrow-right.svg';
import CheckIcon from 'vectors/internal/check.svg';

import {
  NewsletterContainer,
  NewsletterForm,
  NewsletterFieldWrapper,
  NewsletterEmailField,
  NewsletterButton,
} from './styled';

export const Newsletter: React.FC = () => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const {
    register,
    reset,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<NewsLetterForm>({ mode: 'onSubmit' });

  const handleSubscribeToNewsletter = (data: NewsLetterForm) => {
    if (isSuccess) {
      setIsSuccess(false);
      reset();
    } else {
      api
        .post({
          path: '/newsletter/subscribe',
          body: data,
        })
        .then(() => {
          setIsSuccess(true);
          alert(t('Subscription was successful!'));
        })
        .catch(async (error) => {
          setIsSuccess(false);

          const errorData = (await error.json()) as i.ApiError;

          if (errorData?.reason === 'member_exists') {
            alert(t('This email address is already subscribed to the list.'));
          } else {
            sendError({
              type: 'api',
              message: 'Error subscribing to newsletter',
              method: 'handleSubscribeToNewsletter',
              endpoint: '/newsletter/subscribe',
              error,
              params: {
                data,
              },
            });
          }
        });
    }
  };

  return (
    <NewsletterContainer>
      <NewsletterForm
        onSubmit={handleSubmit(handleSubscribeToNewsletter)}
        isFocused={isFocused}
        isValid={isValid}
        isSuccess={isSuccess}
      >
        <NewsletterFieldWrapper>
          <NewsletterEmailField
            type="email"
            autoComplete="off"
            autoCapitalize="off"
            inputMode="email"
            onFocus={() => setIsFocused(true)}
            placeholder={t(isFocused ? 'Email' : 'Sign up for newsletter') as string}
            {...register('email', {
              ...validation.required,
              ...validation.email,
              onBlur: () => setIsFocused(false),
            })}
          />
        </NewsletterFieldWrapper>
        <NewsletterButton
          type="submit"
          isValid={isValid}
          isSuccess={isSuccess}
          disabled={!isValid || isSubmitting}
        >
          {isSuccess ? (
            <CheckIcon width={24} height={24} />
          ) : (
            <ArrowRightIcon width={24} height={24} />
          )}
        </NewsletterButton>
      </NewsletterForm>
    </NewsletterContainer>
  );
};

type NewsLetterForm = {
  email: string;
};
