import { motion } from 'framer-motion';
import styled from 'styled-components';

import { media } from 'styles/utils';
import { hexToRgba } from 'services/style';

export const BlurBackgroundContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 4;
  backdrop-filter: blur(5px);
  background-color: ${({ theme }) => hexToRgba(theme.colors.white, '0.3')};
`;

export const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.springWood};

  ${media.desktop`
    width: 50vw;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
`;

export const BookingHeaderContainer = styled.div`
  padding: 120px 0 100px;
`;
