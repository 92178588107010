import * as i from 'types';
import React from 'react';
import { useRouter } from 'next/router';

import { Container, Title } from './styled';

export const SpotItem: React.FC<SpotItemProps> = ({ spotItem, mapItem, onSelectSpot }) => {
  const [selected, setSelected] = React.useState(false);
  const disabled = ['Unavailable', 'Enrolled'].includes(spotItem.status);
  const router = useRouter();

  const handleSelection = () => {
    if (!disabled) {
      setSelected(!selected);
      onSelectSpot(spotItem);
    }
  };

  if (!mapItem || !spotItem) return null;

  return (
    <Container
      onClick={handleSelection}
      $status={spotItem.status}
      $selected={selected}
      $selectedSpotFriend={router.query.bikeNumber === spotItem.label}
      $position={mapItem.position}
      $disabled={disabled}
    >
      <Title
        $status={spotItem.status}
        $selected={selected}
        $selectedSpotFriend={router.query.bikeNumber === spotItem.label}
      >
        {spotItem.label}
      </Title>
    </Container>
  );
};

type SpotItemProps = {
  spotItem: i.Spot;
  mapItem?: i.StudioLayoutSpot;
  onSelectSpot: (spot: i.Spot) => void;
};
