import * as i from 'types';
import * as React from 'react';
import { useTranslation } from 'next-i18next';

import { SocialMenuBar, LanguageBar, Newsletter, Menu } from './components';
import { FooterMenuContainer, SecondaryLinkContainer } from './styled';

export const FooterMenu: React.FC<FooterMenuProps> = ({ navigation }) => {
  const { t } = useTranslation();

  return (
    <FooterMenuContainer role="navigation" aria-label={t('Navigate in footer') as string}>
      <Menu menuItems={navigation.footerNavigationItems} />
      <Newsletter />
      <SecondaryLinkContainer>
        <SocialMenuBar
          instagram={navigation.instagram}
          youtube={navigation.youtube}
          linkedIn={navigation.linkedIn}
          facebook={navigation.facebook}
        />
        <LanguageBar />
      </SecondaryLinkContainer>
    </FooterMenuContainer>
  );
};

type FooterMenuProps = {
  navigation: i.TypeNavigationListFields;
};
