import { getRandomInt, TWO_PI } from 'services/mathHelpers';
import { Vector2D } from 'services/vector2D';

export class BlurredCircle {
  position: Vector2D;
  context: CanvasRenderingContext2D;

  color: string;
  radius: number;

  horizontalBounceAngle: number;
  horizontalBounceDistance: number;
  horizontalBounceSpeed: number;

  verticalBounceAngle: number;
  verticalBounceDistance: number;
  verticalBounceSpeed: number;

  velocity: Vector2D;

  constructor(x: number, y: number, radius: number, color: string, startingAngle: number, speed: number, context: CanvasRenderingContext2D) {
    this.position = new Vector2D(x, y);
    this.context = context;

    this.color = color;
    this.radius = radius;

    this.horizontalBounceAngle = startingAngle;
    this.horizontalBounceDistance = getRandomInt(1, 3);
    this.horizontalBounceSpeed = speed;

    this.verticalBounceAngle = startingAngle;
    this.verticalBounceDistance = 2;
    this.verticalBounceSpeed = speed / 2;

    this.velocity = new Vector2D(0, 0);
    this.velocity.setLength(Math.random() * 2 + 1);
    this.velocity.setAngle(Math.random() * TWO_PI);
  }

  update() {
    //Calculate the amount of velocity for the bounce
    const bounce = new Vector2D(
      Math.sin(this.horizontalBounceAngle) * this.horizontalBounceDistance,
      Math.sin(this.verticalBounceAngle) * this.verticalBounceSpeed,
    );
    this.position.addTo(bounce);

    //Update the bounce angle by adding the speed
    this.horizontalBounceAngle += this.horizontalBounceSpeed;
    this.verticalBounceAngle += this.verticalBounceSpeed;
  }

  render() {
    this.context.beginPath();
    const rad = this.context.createRadialGradient(
      this.position.getX(),
      this.position.getY(),
      1,
      this.position.getX(),
      this.position.getY(),
      this.radius,
    );
    rad.addColorStop(0, 'rgba(' + this.color + ',0.8)');
    rad.addColorStop(1, 'rgba(' + this.color + ',0)');
    this.context.fillStyle = rad;
    this.context.arc(
      this.position.getX(),
      this.position.getY(),
      this.radius,
      0,
      TWO_PI,
      false,
    );
    this.context.fill();
  }
}
