import React from 'react';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';

import { useGetMe } from 'queries/user';
import UserIcon from 'vectors/internal/user.svg';
import { StyledLink } from 'common/interaction';

export const UserMenu: React.FC = () => {
  const { t } = useTranslation();
  const session = useSession();
  const user = useGetMe();

  if (session.status === 'loading') {
    return (
      <StyledLink href="/app/login" $withIcon>
        <UserIcon />
        ...
      </StyledLink>
    );
  }

  if (session.status === 'authenticated') {
    const firstName = user.data?.firstName || session.data.user.firstName;
    const lastName = user.data?.lastName || session.data.user.lastName;

    return (
      <StyledLink href="/account" $withIcon>
        <UserIcon /> {firstName} {lastName}
      </StyledLink>
    );
  }

  return (
    <StyledLink href="/app/login" $withIcon>
      <UserIcon />
      {t('Login')}
    </StyledLink>
  );
};
