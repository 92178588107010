import * as i from 'types';
import styled, { css } from 'styled-components';

import { hexToRgba } from 'services/style';

export const Container = styled.div<ExtendedTouchableOpacityProps>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
  position: absolute;
  top: ${({ $position }) => $position.y}px;
  left: ${({ $position }) => $position.x}px;
  transition: background-color 0.3s, border 0.3s;

  ${({ $status }) => {
    switch ($status) {
      case 'Available':
        return css`
          border: 1px solid ${({ theme }) => theme.colors.white};
        `;
      case 'Enrolled':
      case 'Unavailable':
        return css`
          background-color: ${({ theme }) => hexToRgba(theme.colors.white, '0.05')};
          cursor: not-allowed;
        `;
    }
  }}

  ${({ $selected }) =>
    $selected &&
    css`
      background-color: ${({ theme }) => theme.colors.bluePurple};
      border: 1px solid ${({ theme }) => theme.colors.bluePurple};
    `}

  ${({ $selectedSpotFriend }) =>
    $selectedSpotFriend &&
    css`
      background-color: ${({ theme }) => theme.colors.yellow};
      border: 1px solid ${({ theme }) => theme.colors.yellow};
    `}
`;

type ExtendedTouchableOpacityProps = {
  $disabled?: boolean;
  $selected?: boolean;
  $selectedSpotFriend?: boolean;
  $status: i.BookingSpotStatus;
  $position: {
    x: number;
    y: number;
  };
};

export const Title = styled.div<TitleProps>`
  font-family: ${({ theme }) => theme.fonts.bigShouldersDisplay};
  font-size: 18px;
  color: ${({ theme }) => hexToRgba(theme.colors.white, '0.1')};

  ${({ $selectedSpotFriend, $status }) =>
    ($selectedSpotFriend || $status === 'Available') &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}

  ${({ $selected }) =>
    $selected &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}
`;

type TitleProps = {
  $selected?: boolean;
  $status: i.BookingSpotStatus;
  $selectedSpotFriend?: boolean;
};
