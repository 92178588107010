import React from 'react';

import FacebookIcon from 'vectors/internal/facebook.svg';
import LinkedInIcon from 'vectors/internal/linkedin.svg';
import YoutubeIcon from 'vectors/internal/youtube.svg';
import { StyledLink } from 'common/interaction';

import { SocialMenuBarContainer, InstagramIcon } from './styled';

export const SocialMenuBar: React.FC<SocialMenuBarProps> = ({
  instagram,
  youtube,
  linkedIn,
  facebook,
}) => {
  return (
    <SocialMenuBarContainer>
      <StyledLink href={instagram} target="_blank">
        <InstagramIcon height="21" width="22" />
      </StyledLink>
      <StyledLink href={youtube} target="_blank">
        <YoutubeIcon />
      </StyledLink>
      <StyledLink href={linkedIn} target="_blank">
        <LinkedInIcon />
      </StyledLink>
      <StyledLink href={facebook} target="_blank">
        <FacebookIcon />
      </StyledLink>
    </SocialMenuBarContainer>
  );
};

type SocialMenuBarProps = {
  instagram: string;
  youtube: string;
  linkedIn: string;
  facebook: string;
};
