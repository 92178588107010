import React from 'react';
import { useRouter } from 'next/router';

import CheckmarkIcon from 'vectors/internal/checkmark.svg';

import { LanguageBarContainer, LanguageBarItem, LanguageText } from './styled';

export const LanguageBar = () => {
  const router = useRouter();

  const onChangeLocale = (selectedLocale: string) => {
    const locale = selectedLocale ?? router.defaultLocale!;
    const now = new Date();
    now.setFullYear(now.getFullYear() + 1);
    const expires = now.toUTCString();

    document.cookie = `NEXT_LOCALE=${locale}; expires=${expires}; path=/`;
    router.push(router.pathname, router.asPath, { locale });
  };

  return (
    <LanguageBarContainer>
      {router.locales?.map((locale) => (
        <LanguageBarItem
          key={locale}
          selected={router.locale === locale}
          onClick={() => onChangeLocale(locale)}
        >
          <LanguageText>
            {router.locale === locale && <CheckmarkIcon />}
            {locale}
          </LanguageText>
        </LanguageBarItem>
      ))}
    </LanguageBarContainer>
  );
};
