export class Vector2D {
  _x: number;
  _y: number;

  constructor(x: number, y: number) {
    this._x = x;
    this._y = y;
  }

  setX(x: number) {
    this._x = x;
  }

  setY(y: number) {
    this._y = y;
  }

  getX() {
    return this._x;
  }

  getY() {
    return this._y;
  }

  setAngle(angle: number) {
    const length = this.getLength();
    this._x = Math.cos(angle) * length;
    this._y = Math.sin(angle) * length;
  }

  getAngle() {
    return Math.atan2(this._y, this._x);
  }

  setLength(length: number) {
    const angle = this.getAngle();
    this._x = Math.cos(angle) * length;
    this._y = Math.sin(angle) * length;
  }

  getLength() {
    return Math.sqrt(this._x * this._x + this._y * this._y);
  }

  add(v2: Vector2D) {
    return new Vector2D(this._x + v2.getX(), this._y + v2.getY());
  }

  subtract(v2: Vector2D) {
    return new Vector2D(this._x - v2.getX(), this._y - v2.getY());
  }

  multiply(value: number) {
    return new Vector2D(this._x * value, this._y * value);
  }

  divide(value: number) {
    return new Vector2D(this._x / value, this._y / value);
  }

  addTo(v2: Vector2D) {
    this._x += v2.getX();
    this._y += v2.getY();
  }

  subtractFrom(v2: Vector2D) {
    this._x -= v2.getX();
    this._y -= v2.getY();
  }

  multiplyBy(value: number) {
    this._x *= value;
    this._y *= value;
  }

  divideBy(value: number) {
    this._x /= value;
    this._y /= value;
  }
}
