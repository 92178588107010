import * as i from 'types';
import React from 'react';

import { MenuContainer, MenuLink } from './styled';

export const Menu: React.FC<MenuProps> = ({ menuItems }) => {
  return (
    <MenuContainer>
      {menuItems.map((menuItem) => (
        <MenuLink
          key={menuItem.sys.id}
          href={menuItem.fields.url}
          {...(menuItem.fields.openInNewTab && {
            target: '_blank',
          })}
        >
          {menuItem.fields.title}
        </MenuLink>
      ))}
    </MenuContainer>
  );
};

type MenuProps = {
  menuItems: i.TypeNavigationItem[];
};
