import { BlurredCircle } from './blurredCircle';

const variantColors = [
  ['222,210,187', '221,210,207'],
  ['246,185,0', '229,57,80'],
  ['89,147,236', '234,137,184'],
  ['255,255,0', '222,209,185'],
];

export class AnimationBase {
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  width: number;
  height: number;
  position: 'top' | 'bottom';

  variant: number;
  blurredCircles: BlurredCircle[];

  constructor(canvas: HTMLCanvasElement, width: number, height: number, variant: number, position: 'top' | 'bottom') {
    this.canvas = canvas;
    this.context = this.canvas.getContext('2d') as CanvasRenderingContext2D;
    this.width = this.canvas.width = width;
    this.height = this.canvas.height = height;
    this.position = position;

    this.variant = variant;
    this.blurredCircles = [];
  }

  resizeCanvas(width: number, height: number) {
    this.width = this.canvas.width = width;
    this.height = this.canvas.height = height;

    //Empty the previous container and fill it again with new BlurredCircle objects
    this.blurredCircles = [];
    this.initializeBlurredCircles();
  }

  initializeBlurredCircles() {
    const redCircle = new BlurredCircle(
      (this.width / 2) + 200,
      this.position === 'bottom' ? this.height : 0,
      (this.height / 2) + 250,
      variantColors[this.variant][0],
      10,
      0.005,
      this.context,
    );
    this.blurredCircles.push(redCircle);

    const orangeCircle = new BlurredCircle(
      (this.width / 2) - 350,
      this.position === 'bottom' ? this.height : 0,
      (this.height / 2) + 250,
      variantColors[this.variant][1],
      70,
      0.01,
      this.context,
    );
    this.blurredCircles.push(orangeCircle);
  }

  update() {
    for (let i = 0; i < this.blurredCircles.length; i++) {
      this.blurredCircles[i].update();
    }
  }

  render() {
    //Clear the entire canvas every render
    this.context.clearRect(0, 0, this.width, this.height);

    //Trigger the render function on every child element
    for (let i = 0; i < this.blurredCircles.length; i++) {
      this.blurredCircles[i].render();
    }
  }

  loop() {
    this.update();
    this.render();

    window.requestAnimationFrame(() => this.loop());
  }
}
