import * as i from 'types';
import React from 'react';
import { useTranslation } from 'next-i18next';

import { daysCount } from 'services/date';
import CalendarIcon from 'vectors/internal/calendar-general.svg';
import { ProgressRing } from 'common/interaction';
import { LocationPin, Tag } from 'common/layout';

import {
  Container,
  CreditCountContainer,
  Details,
  Title,
  TagContainer,
  ArrowContainer,
  ArrowIcon,
} from './styled';

export const BookingSeriesItem: React.FC<BookingSeriesItemProps> = ({
  item,
  rocycleClass,
  isSelected,
  onSelect,
  location,
}) => {
  const { t } = useTranslation();

  return (
    <Container
      $isSelected={isSelected}
      {...(onSelect && {
        onClick: () => onSelect(item),
      })}
    >
      <CreditCountContainer>
        <ProgressRing
          stroke={3}
          size={64}
          progress={(item.classesRemaining / item.classCount) * 100}
          color={['green', 'dark']}
        />
        {item.classesRemaining}/{item.classCount}
      </CreditCountContainer>
      <Details>
        <Title>{item.seriesName}</Title>
        <TagContainer>
          <Tag small>
            {item.frozen ? (
              'Credits frozen'
            ) : (
              <>
                <CalendarIcon />
                {daysCount(item.expirationDate || item.expiringDate!)} days left
              </>
            )}
          </Tag>
          {rocycleClass && (
            <Tag small>
              <LocationPin />
              {rocycleClass.classDetails.site}
            </Tag>
          )}
          {location && (
            <Tag small>
              <LocationPin />
              {location}
            </Tag>
          )}
          {isSelected && <Tag small>{t('Selected')}</Tag>}
        </TagContainer>
      </Details>
      <ArrowContainer>{onSelect && <ArrowIcon />}</ArrowContainer>
    </Container>
  );
};

type BookingSeriesItemProps = {
  item: i.UserSeries;
  isSelected?: boolean;
  rocycleClass?: i.ClassDetail | null | undefined;
  onSelect?: (series: i.UserSeries) => void;
  location?: string;
};
