import styled, { css } from 'styled-components';

import { LinkStyles } from 'common/interaction';
import { Text } from 'common/typography';

export const LanguageBarItem = styled.button<LanguageBarItemProps>`
  appearance: none;
  background-color: transparent;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.text};

  svg {
    margin-right: 10px;
  }

  ${({ selected }) =>
    selected &&
    css`
      border-radius: 20px;
    `}
`;

type LanguageBarItemProps = {
  selected?: boolean;
};

export const LanguageBarContainer = styled.div`
  display: flex;
  align-items: center;

  ${LanguageBarItem}:not(:first-child) {
    margin-left: 10px;
  }
`;

export const LanguageText = styled(Text)`
  ${LinkStyles}
  padding: 0;
  white-space: nowrap;
`;
