const weekDays = [
  'weekday.monday',
  'weekday.tuesday',
  'weekday.wednesday',
  'weekday.thursday',
  'weekday.friday',
  'weekday.saturday',
  'weekday.sunday',
];

export const getDays = () => weekDays;
