import * as i from 'types';
import React from 'react';

import { useInstructorThumbnail } from 'hooks';

import { SpotInstructor } from '../SpotInstructor';
import { SpotItem } from '../SpotItem';
import { PlaceholderContainer, Container } from './styled';

export const SpotMap: React.FC<SpotMapProps> = ({ rocycleClass, layout, onSelectSpot }) => {
  const { instructorId, instructorName } = rocycleClass.classDetails;
  const instructorThumbnail = useInstructorThumbnail({
    id: instructorId,
    name: instructorName,
    size: 100,
  });

  return (
    <PlaceholderContainer>
      <Container dimensions={layout.dimensions}>
        {rocycleClass.spots.map((spot) => (
          <SpotItem
            key={spot.id}
            spotItem={spot}
            mapItem={layout.spots.find((mapSpot) => mapSpot.id === spot.id)}
            onSelectSpot={onSelectSpot}
          />
        ))}
        <SpotInstructor thumbnail={instructorThumbnail} position={layout.instructor.position} />
      </Container>
    </PlaceholderContainer>
  );
};

type SpotMapProps = {
  rocycleClass: i.ClassDetail;
  layout: i.StudioLayout;
  onSelectSpot: (spot: i.Spot) => void;
};
