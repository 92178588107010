import React from 'react';
import { useTranslation, Trans } from 'next-i18next';

import { useSize } from 'services/hooks';
import { BlurredCirclesBackground } from 'common/layout';

import {
  ContentContainer,
  ExtendedSignatureBigShouldersTitle,
  ExtendedText,
  BottomContainer,
  TopContainer,
  ContentWrapper,
  BackHomeButton,
} from './styled';

export const BookingWaitlistSuccess: React.FC<BookingWaitlistSuccessProps> = ({
  toggleSidebar,
}) => {
  const { t } = useTranslation();
  const target = React.useRef<HTMLDivElement | null>(null);
  const [width, height] = useSize(target);

  return (
    <ContentWrapper ref={target}>
      <BlurredCirclesBackground width={width} height={height}>
        <ContentContainer>
          <TopContainer>
            <ExtendedSignatureBigShouldersTitle>
              <Trans i18nKey="booking.waitlist">
                <i>You are</i> on the waitlist!
              </Trans>
            </ExtendedSignatureBigShouldersTitle>
          </TopContainer>
          <BottomContainer>
            <ExtendedText>
              <Trans i18nKey="booking.full">
                This class is full, but no worries there might be a spot for you after all. We will
                send you an email as soon as we have a bike for you.
              </Trans>
            </ExtendedText>
            <BackHomeButton onClick={toggleSidebar}>
              {t('Back home')} {'->'}
            </BackHomeButton>
          </BottomContainer>
        </ContentContainer>
      </BlurredCirclesBackground>
    </ContentWrapper>
  );
};

type BookingWaitlistSuccessProps = {
  toggleSidebar: () => void;
};
