import React from 'react';
import ReactDOM from 'react-dom';
import { AnimatePresence } from 'framer-motion';

import { isServer } from 'services';
import { lockBodyScroll } from 'services/lockBodyScroll';
import CloseIcon from 'vectors/internal/close.svg';

import { Container, StyledModal, Overlay, CloseButton } from './styled';

const ModalContent: React.FC<ModalProps> = ({ children, isOpen, onClose }) => {
  const overlayRef = React.createRef<HTMLDivElement>();
  const modalRoot = isServer ? null : (document.getElementById('modal') as HTMLElement);

  const onEscKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') onClose();
  };

  React.useEffect(() => {
    window.addEventListener('keydown', onEscKeyDown, false);

    return () => {
      window.addEventListener('keydown', onEscKeyDown, false);
    };
  }, []);

  React.useEffect(() => {
    lockBodyScroll(isOpen);

    return () => {
      lockBodyScroll(false);
    };
  }, [isOpen]);

  if (!modalRoot) return null;

  return ReactDOM.createPortal(
    <Container key="modal" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <StyledModal>
        {children}
        <CloseButton onClick={() => onClose()}>
          <CloseIcon />
        </CloseButton>
      </StyledModal>
      <Overlay ref={overlayRef} onClick={() => onClose()} />
    </Container>,
    modalRoot,
  );
};

export const Modal: React.FC<ModalProps> = ({ children, isOpen, onClose }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <ModalContent isOpen={isOpen} onClose={onClose}>
          {children}
        </ModalContent>
      )}
    </AnimatePresence>
  );
};

type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
};
