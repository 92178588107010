import styled from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';

import { Headline3, Text } from 'common/typography';

export const OpenFilterButton = styled.button`
  appearance: none;
  position: relative;
  background-color: transparent;
  margin: 20px auto 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  align-items: center;

  svg {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
`;

export const FilterMenu = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 100px;
  width: 100%;
  height: calc(100vh - 100px);
  background: ${({ theme }) => theme.colors.white.dirty};
  z-index: 10;
  padding: 40px 30px 14px;
  overflow-y: auto;
  border-top: 2px solid ${({ theme }) => theme.colors.darkRed};
`;

export const Title = styled(Headline3)`
  text-align: center;
  width: 250px;
  margin: 0 auto;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
`;

export const ButtonContainer = styled.div`
  margin-top: auto;
  margin-bottom: 110px;

  > button {
    margin-left: 0;
  }

  ${media.tablet`
    margin-bottom: 0;
  `}
`;

export const Indicator = styled(Text)`
  position: absolute;
  top: -6px;
  right: -12px;
  margin-left: 4px;
`;
