import styled from 'styled-components';

import { media } from 'styles/utils';
import { Headline3 } from 'common/typography';
import { Button } from 'common/interaction';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const HeaderContainer = styled.div`
  padding: 120px 0 20px;

  ${media.desktop`
    padding: 120px 0 100px;
  `}
`;

export const Title = styled(Headline3)`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
`;

export const ExtendedButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
`;

export const ScrollIndicatorContainer = styled.div`
  p {
    color: ${({ theme }) => theme.colors.white};
  }

  ${media.desktop`
    display: none;
  `}
`;
