import styled from 'styled-components';

import { LinkStyles } from 'common/interaction';
import { SignatureBigShouldersTitle, Text } from 'common/typography';

export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
`;

export const ExtendedSignatureBigShouldersTitle = styled(SignatureBigShouldersTitle)`
  font-size: 96px;
  max-width: 340px;

  & > i {
    font-size: 60px;
  }
`;

export const ExtendedText = styled(Text)`
  opacity: 0.6;
  max-width: 285px;
  text-align: center;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: -0.01em;
  margin-bottom: 53px;
  font-feature-settings: 'ordn' on, 'ss04' on, 'ss06' on, 'liga' off;
`;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

export const BackHomeButton = styled.button`
  ${LinkStyles}
  margin-bottom: 57px;
`;
